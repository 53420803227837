import template from './l_overview_page.html';
import LocationsViewAPI from './../location_view_api.js'

class LocationOverviewViewModel
{
	constructor (page)
	{
		this.page = page;
		this.loaded = ko.observable(false);
		this.location_id = ko.observable(this.page.bindings.location_id);
		this.location_details = ko.observableArray();
		this.location_fields = ko.observable();
		this.billing_details = ko.observableArray();
		this.contact_details = ko.observableArray();
		this.logo_path = ko.observable();
		this.logo_image = ko.observable();
		this.upload_file = ko.observable();
		this.title = ko.observable();
		this.can_edit = ko.observable(false);
		this.location_children = ko.observableArray([]);
	}

	async edit_location_click ()
	{
		if (this.location_details().location_type === 'Supplier')
		{
			await Grape.dialog.open('EditSupplier', { location_id: this.location_id(), fields: this.location_fields() || [] });
		}
		else
			await Grape.dialog.open('EditLocation', { location_id: this.location_id(), fields: this.location_fields() || [] });

		this.page.update_data();
	}

	async edit_billing_click (row)
	{
		await Grape.dialog.open('EditBilling', { location_id: this.location_id(), data: row });
		this.page.update_data();
	}

	async add_billing_click ()
	{
		await Grape.dialog.open('EditBilling', { location_id: this.location_id(), data: {} });
		this.page.update_data();
	}

	async edit_contact_click (row)
	{
		await Grape.dialog.open('EditContact', { location_id: this.location_id(), data: row });
		this.page.update_data();
	}

	async add_contact_click ()
	{
		await Grape.dialog.open('EditContact', { location_id: this.location_id(), data: {} });
		this.page.update_data();
	}

	async upload_click () 
	{
		const hiddenFileInput = document.querySelector('.image_upload');

		const changeListener = async () => {
			const selectedFile = hiddenFileInput.files[0];
	
			if (selectedFile)
			{
				await window.Grape.StockUtils.fileupload({
					form_id: document.getElementById('image_upload_form'),
					api: '/api/stock-management/location/logo/upload',
					header_info: {location_id: this.location_id()},
					success_message: 'Image successfully uploaded!'
				});

				this.page.update_data();

				hiddenFileInput.removeEventListener('change', changeListener);
			}
		};

		hiddenFileInput.addEventListener('change', changeListener);
		hiddenFileInput.click();
	}
}

class LocationOverviewPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new LocationOverviewViewModel(this);
		this.LocationsViewAPI = new LocationsViewAPI(this.viewModel.location_id());
	}

	async init ()
	{
		document.title = 'Location Overview';
		await this.update_data();

		let editable_locations = await window.Grape.StockUtils.get_user_locations('EditLocation');
		let editable = false;
		if (Grape.currentSession.roles.includes('stock.all-location-permissions') || editable_locations.find(loc => loc.location_name == this.viewModel.title()))
			editable = true;

		this.viewModel.can_edit(editable);
	}

	async update_data ()
	{
		//location details
		let result = await this.LocationsViewAPI.getViewRecords('v_locations');

		this.viewModel.location_details(result.records[0]);

		if (result.records[0].name != '' || !result.records[0].name)
			this.viewModel.title(result.records[0].name);

		await this.loadDetailedInfo();

		//TODO prevent call in a different way once decision is made on fields
		if (this.viewModel.logo_path())
			await this.loadLocationLogo();
	}

	async loadDetailedInfo ()
	{
		try
		{
			let result = await Grape.fetches.getJSON('/api/stock-management/location', { 'location_id': this.viewModel.location_id() });

			if (result.status != 'ERROR')
			{
				this.viewModel.billing_details(result.billing);
				this.viewModel.contact_details(result.contact);
				this.viewModel.location_children(result.children);

				if (result.fields != null)
				{
					this.viewModel.location_fields(result.fields);

					if (result.fields['company-logo'])
						this.viewModel.logo_path(result.fields['company-logo'].path + '/' + result.fields['company-logo'].filename);
					else if (result.fields['supplier-logo'])
						this.viewModel.logo_path(result.fields['supplier-logo'].path + '/' + result.fields['supplier-logo'].filename);
				}
			}
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error)
		}
	}

	async loadLocationLogo ()
	{
		try
		{
			let result = await fetch(`/api/stock-management/location/logo/download/${this.viewModel.location_id()}`, {
				method: 'GET',
				headers: { 'Content-Type': 'image/png' }
			});

			if (result.ok)
			{
				let blob = await result.blob()
				let img = document.getElementById('logo');
				img.src = URL.createObjectURL(blob);
			}
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error)
		}
	}
}

export default {
	route: '[/location/]l_overview',
	page_id: 'l_overview',
	page_class: LocationOverviewPage,
	template: template
};