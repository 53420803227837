import template from './sales_orders.html';

class SalesOrderViewModel
{
	constructor (page)
	{
		this.page = page;
	}
}

class SalesOrderPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new SalesOrderViewModel(this);
	}
}

export default {
	route: '/stock/order/sales_orders/',
	page_class: SalesOrderPage,
	template: template
}