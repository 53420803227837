import template from './stock_item.html';

class StockItemViewModel
{
	constructor (page)
	{
		this.page = page;
		this.stock_item_id = ko.observable();
		this.type = ko.observable();
		this.available_items = ko.observableArray();
		this.stock_description = ko.observable();
		this.selected_stock_item = ko.observableArray([]);
		this.tabs = ko.observableArray([
			{
				route: '[/stock_item/]si_overview',
				title: 'Overview',
				icon: 'fa-solid fa-info-circle fa-lg'
			},
			{
				route: '[/stock_item/]si_levels',
				title: 'Levels',
				icon: 'fa-solid fa-magnifying-glass-chart fa-lg'
			},
			{
				route: '[/stock_item/]si_orders',
				title: 'Orders',
				icon: 'fa-solid fa-bags-shopping fa-lg'
			},
			{
				route: '[/stock_item/]si_movements',
				title: 'Movements',
				icon: 'fa-solid fa-arrow-trend-up fa-lg'
			},
			{
				route: '[/stock_item/]si_pricing',
				title: 'Pricing',
				icon: 'fa-solid fa-circle-dollar fa-lg'
			},
			{
				route: '[/stock_item/]si_files',
				title: 'Files',
				icon: 'fa-solid fa-file fa-lg'
			}
		]); 
		this.current_route = ko.observable();

		this.selected_stock_item.subscribe((new_stock_items) => {
			if (new_stock_items.length > 1)
			{
				let current_stock_item = new_stock_items.splice(new_stock_items.indexOf(this.stock_item_id()), 1);

				localStorage.setItem('movement_summary_last_stock_item', current_stock_item[0].stock_item_id);
				if (current_stock_item[0].stock_item_id !== this.stock_item_id())
					Grape.navigate(`stock/item/view/${current_stock_item[0].stock_item_id}/${current_stock_item[0].is_bom ? 'bom' : 'stock_item'}`);
			}
		});
	}

	load_tab (route)
	{
		window.localStorage.setItem('last_stock_item_page', route);
		const element = document.querySelector('.stock_item_page_content');
		this.current_route(route);
		const page = Grape.pages.find_page_by_id(route);
		if (!page)
			throw new Error(`Page with id "${route}" not found`);

		let params = { stock_item_id: this.stock_item_id(), type: this.type() };

		if (this.type() === 'bom')
			params.stock_item = this.selected_stock_item()[0];

		Grape.pages.load_page(page, element, params, {}, () => {});
	}
}

class StockItemViewPage
{
	constructor(bindings, element)
	{
		this.viewModel = new StockItemViewModel(this);
		this.bindings = bindings;
		this.element = element;
		this.viewModel.stock_item_id(this.bindings.stock_item_id);
		this.viewModel.type(this.bindings.type || 'stock_item');
	}
	
	async init ()
	{
		document.title = 'Stock Item';
		let last_item_page = window.localStorage.getItem('last_stock_item_page');
		if (last_item_page && !(this.viewModel.type() === 'stock_item' && last_item_page === '[/stock_item/]si_production'))
			this.viewModel.load_tab(last_item_page);
		else
			this.viewModel.load_tab('[/stock_item/]si_overview');

		let stock_items = await Grape.cache.fetch('StockItems');
		let stock_item = stock_items.filter(item => JSON.stringify(item.stock_item_id) === this.viewModel.stock_item_id());
		this.viewModel.stock_description(stock_item[0].description);
		this.viewModel.selected_stock_item.push(stock_item[0]);
		this.viewModel.available_items(stock_items);

		if (this.viewModel.type() === 'bom')
			this.viewModel.tabs.push({
				route: '[/stock_item/]si_production',
				title: 'Production',
				icon: 'fa fa-conveyor-belt-arm'
			});
	}

	async btn_add_new_item_click ()
	{
		let navigation = '';
		try
		{
			let obj = await Grape.dialog.open('StockItemEdit', { type: this.viewModel.type() });

			if (!obj)
				return;

			let url = '/api/stock-management/stock-item';

			if (this.viewModel.type() == 'bom')
			{
				url = '/api/stock-management/bom'
				let bom_obj = await Grape.dialog.open('BomItemEdit', {});

				if (bom_obj === undefined)
					return;

				if (bom_obj.error)
					throw new Error(bom_obj.error);

				obj = {...obj, ...bom_obj};
			}

			let result = await Grape.fetches.postJSON(url, obj);

			if (result.status != 'ERROR')
			{
				if (this.viewModel.type() == 'bom')
					navigation = `/stock/item/view/${result.stock_item_id}/bom`;
				else
					navigation = `/stock/item/view/${result.stock_item_id}/stock_item`;
				
				Grape.alerts.alert({
					type: 'success', 
					message: 'Product ' + obj.description + ' saved', 
					title: 'New Product Saved', 
				});
			}
			else
				throw new Error(result.message);
		} catch (error) {
			Grape.alerts.alert({ title: 'Error', type: 'error', message: error });
			console.error(error);
		} finally {
			await Grape.cache.refresh('StockItems');
			Grape.cache.refresh('ActiveStockItems');
			Grape.cache.refresh('StockItemTags');
			Grape.navigate(navigation);
		}
	}
}

export default {
	route: '[/]stock/item/view/:stock_item_id/:type',
	page_class: StockItemViewPage,
	template: template
}