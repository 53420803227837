import template from './add-price-dropdown.html';

class AddPriceComponentViewModel
{
	constructor (params)
	{
		this.params = params;
		this.location_id = ko_helper.safe_observable(params.location_id || null);
		this.stock_item_id = ko_helper.safe_observable(params.stock_item_id || null);

		this.price = ko.observable(0);
		this.min_order_qty = ko.observable(0);
		this.date_effective = ko.observable(moment().format('YYYY-MM-DD'));
		this.available_items = ko.observableArray([]);
		this.selected_items = ko.observableArray([]);

		this.search_text = ko.observable('');

		this.filtered_items = ko.computed(() => {
			let search_term = this.search_text().toLowerCase();

			if (!search_term)
				return this.available_items();

			return this.available_items().filter(item => {
				let name = (item.name || '').toLowerCase();
				let desc = (item.description || '').toLowerCase();
				return name.includes(search_term) || desc.includes(search_term);
			});
		});

		this.updateData();
	}

	async updateData ()
	{
		try
		{
			if (this.location_id() && this.stock_item_id())
				throw new Error('Pass either location_id or stock_item_id, not both!');

			if (!this.location_id() && !this.stock_item_id())
				throw new Error('Pass either location_id or stock_item_id!');

			let items;

			if (this.location_id())
				items = await Grape.cache.fetch('ActiveStockItems');

			if (this.stock_item_id())
				items = await Grape.cache.fetch('ActiveLocations');

			this.available_items(items);

		} catch (error) {
			Grape.alerts.alert({ title: 'Error', type: 'error', message: error.message });
			console.error(error);
		}
	}

	item_click (row)
	{
		if (this.selected_items.indexOf(row) !== -1)
			this.selected_items.remove(row);
		else
			this.selected_items.push(row);
	}

	select_all_click ()
	{
		if (this.selected_items().length === this.available_items().length)
			this.selected_items([]);
		else
			this.selected_items(this.available_items().slice());
	}

	async btn_save_price_click ()
	{
		try
		{
			let data = this.selected_items().map(item => ({
				stock_item_id: this.stock_item_id() == null ? item.stock_item_id : this.stock_item_id(),
				location_id: this.location_id() == null ? item.location_id : this.location_id(),
				prices: {
					add: [{
						price: this.price(),
						min_order_qty: this.min_order_qty(),
						date_effective: this.date_effective()
					}]
				}
			}));

			for (let d of data)
			{
				let result = await Grape.fetches.postJSON('/api/stock-management/stock-item-location/location', d);
				
				if (result.status !== 'OK')
					throw new Error(result.message || result.code);
			}
			Grape.alerts.alert({ title: 'Success', type: 'success', message: 'Linked successfully!' });
			
		} catch (error) {
			Grape.alerts.alert({ title: 'Error', type: 'error', message: error.message });
			console.error(error);
		}
	}
}

export default {
	name: 'add-price-dropdown',
	viewModel: AddPriceComponentViewModel,
	module_type: 'ko',
	template: template
}