import template from './stock-item-dropdown.html';

/**
* @class StockItemDropdownViewModel
* @constructor 
* @param {array[]} [available_items] - list of available stock items
* @param {array[]} selected_items - list of selected stock items
* @param {bool} [show_hidden_items=false] - shows active stock items (false) or all stock items (true)
* @param {text} [title] - title of the dropdown
*/
class StockItemDropdownViewModel
{
	constructor (params)
	{
		this.params = params;

		this.available_items = ko_helper.safe_observableArray(params.available_items || []);
		this.filtered_items = ko.observableArray([]);
		this.selected_items = ko_helper.safe_observableArray(params.selected_items || '');
		this.show_hidden_items = ko_helper.safe_observable(params.show_hidden_items || false);
		this.show_carried_items = ko_helper.safe_observable(params.show_carried_items || false); // if true only in stock items will be shown
		this.show_carried_items_switch = ko.observable(false);
		this.title = ko_helper.safe_observable(params.title || 'Select/Search item...');
		this.search_text = ko.observable('');

		this.filtered_items = ko.computed(() => {
			let low_case_text = this.search_text().toLowerCase();

			// Items that match the search low_text
			let matched_items = [];
			if (this.available_items().length > 0)
				matched_items = this.available_items().filter(item =>
					(item.description && item.description.toLowerCase().includes(low_case_text))
					|| (item.code && item.code.toLowerCase().includes(low_case_text))
				);

			this.selected_items().forEach(item => {
				if (!matched_items.some(si => si.stock_item_id === item.stock_item_id))
					matched_items.push(item);
			});

			return matched_items;
		});

		this.show_carried_items.subscribe(() => this.loadData());

		if (!params.available_items)
		{
			this.show_carried_items_switch(true);
			this.loadData();
		}
	}

	async loadData ()
	{
		let stock_items;
		if (this.show_carried_items())
			if (this.show_hidden_items())
				stock_items = await Grape.cache.fetch('CarriedStockItems');
			else
				stock_items = await Grape.cache.fetch('ActiveCarriedStockItems');
		else
			if (this.show_hidden_items())
				stock_items = await Grape.cache.fetch('StockItems');
			else
				stock_items = await Grape.cache.fetch('ActiveStockItems');

		this.available_items(stock_items);
	}

	item_click (row, e)
	{
		//to prevent dialog from closing onclick
		e.stopPropagation();

		let items = this.selected_items();
		let index = -1;
		let count = 0;
		items.forEach((item) => {
			if (item.stock_item_id == row.stock_item_id)
				index = count;

			count++;
		});

		if (index == -1)
			items.push(row);
		else
			items.splice(index, 1);

		this.selected_items(items);

		return true;
	}

	is_selected (item) 
	{
		return this.selected_items().some(selected_item => selected_item.description === item.description)
	}
}

export default {
	name: 'stock-item-dropdown',
	viewModel: StockItemDropdownViewModel,
	module_type: 'ko',
	template: template
}