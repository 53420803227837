import template from './create_order.html';

class CreateOrderViewModel
{
	constructor (page)
	{
		this.page = page;
		this.loading = ko.observable(true);
		this.order = ko.observableArray([]);
		this.type = ko.observable(this.page.bindings.type);
		this.order_items_type = ko.observable(this.page.bindings.type);
		this.order_id = ko.observable(this.page.bindings.order_id);
		this.reference_numbers = ko.observableArray([]);
		this.new_reference_number = ko.observable();
		this.order_pricing = ko.observableArray([]);
		this.order_fields = ko.observableArray([]);
		this.order_audit_log = ko.observableArray([]);
		this.locations = ko.observableArray([]);
		this.filtered_locations = ko.observableArray([]);
		this.stock_items = ko.observableArray([]);
		this.delivery_docs = ko.observableArray([]);
		this.delivery_doc = ko.observable();
		this.stock_order_items = ko.observableArray([]);
		this.initial_selected_items = ko.observableArray([]);

		this.order_types = ko.observableArray([]);
		this.order_statuses = ko.observableArray([]);
		this.acceptStatus = ko.observable();
		this.rejectStatus = ko.observable();

		this.is_type_from_list = ko.observable(false);

		this.selectedType = ko.observable();
		this.selectedStatus = ko.observable();
		this.selectedSource = ko.observable();
		this.selectedDestination = ko.observable();
		this.delivery_date = ko.observable();
		this.order_date = ko.observable();
		this.order_number = ko.observable();
		this.order_note = ko.observable();
		this.order_documents = ko.observableArray([]);
		this.order_transactions = ko.observableArray([]);
		this.edited_tax = ko.observableArray([]);

		this.can_send_delivery = ko.observable(false);
		this.can_receive_delivery = ko.observable(false);

		this.filteredSourceLocations = ko.observableArray([]);
		this.filteredDestinationLocations = ko.observableArray([]);

		this.can_reject_order = ko.observable(false);

		this.type.subscribe(() => this.update_order_items_type() );

		this.selectedType.subscribe((type) => {
			if (type) 
			{
				if (this.type() === 'create' && type.type === 'Production Order')
					Grape.navigate('/production/order/');

				if (this.type() === 'create' && type.type === 'Production Order')
					Grape.navigate('/work/order/create');


				if (Array.isArray(type.source_location_types) && type.source_location_types.length > 0)
					this.filteredSourceLocations(this.filtered_locations().filter(location => type.source_location_types.includes(location.location_type)));
				else
					this.filteredSourceLocations([]);

				if (Array.isArray(type.target_location_types) && type.target_location_types.length > 0)
					this.filteredDestinationLocations(this.locations().filter(location => type.target_location_types?.includes(location.location_type)));
				else
					this.filteredDestinationLocations([]);
			}

			this.selectedSource('')
			this.selectedDestination('');

			this.selectedStatus(null);
			let initialStatus;
			
			if (type && !this.order_id() && !this.selectedStatus())
				initialStatus = type.statuses.find((x) => x.initial);
		
			let filtered_statuses;
			
			if (initialStatus)
				filtered_statuses = [initialStatus];
			else if (type)
			{
				let selectedStatus = this.order().order_status;
				let current_status = type.statuses.find(status => status.status === selectedStatus);
				filtered_statuses = type.statuses.filter((x) => {
					return x.status === selectedStatus || (current_status && current_status.allowed_status.includes(x.status));
				});
			}
			
			this.order_statuses(filtered_statuses);
			if (initialStatus) 
				this.selectedStatus(initialStatus);
		});
		
		this.selectedSource.subscribe(async (newVal) => {
			if (newVal && newVal.location_type != 'Supplier')
				this.can_send_delivery(true);
			
			if (newVal)
			{
				await this.page.populate_supplier_stock(newVal);
				this.page.get_order_item_pricing();
			}
		});

		this.selectedDestination.subscribe((newValue) => {
			if (newValue && newValue.location_type != 'Supplier')
				this.can_receive_delivery(true);
		});

		this.show_order_nr_on_create = ko.computed(() => {
			if (Grape.config.public_settings.display_order_nr_on_create && this.type() == 'create')
				return true;
			else 
				return false;
		});

		this.loading(false);
	}

	btn_download_grn_click (item) 
	{
		window.open(`/api/stock-management/GRN/generate?order_id=${this.order_id()}&order_transaction_id=${item.order_transaction_id}`);
	}

	// Event handlers
	btn_toggle_edit_click (row) 
	{
		if (row.isEditing()) 
		{
			let taxes = {};
			
			for (let i = 0; i < row.taxes.length; i++)
				row.taxes[i].type = 'tax_perc';
			taxes = row.taxes;

			this.edited_tax(taxes);
			this.page.save_order();
		}

		row.isEditing(!row.isEditing());
		let iconElement = event.target;
		iconElement.classList.toggle('fa-edit');
		iconElement.classList.toggle('fa-check');

		if (iconElement.classList.contains('fa-check'))
			iconElement.style.color = '#21CA59';
		else 
			iconElement.style.color = '';
	}

	async btn_reject_status_click (status) 
	{
		if (!this.can_reject_order())
		{
			console.error('You must have the stock.super_user access role to reject order.');
			return;
		}

		let result = await Grape.alerts.confirm({
			message: 'Are you sure you want to set the order status to cancelled? This cannot be undone.',
			title: 'Cancel Order', 
			type: 'warning'
		});

		if (result) 
		{
			let selectedStatus = status;
			let save_status = this.selectedType().statuses.find(status => status.status === selectedStatus);
			this.selectedStatus(save_status);
			this.page.save_order();
		}
	}

	async btn_accept_status_click (currentStatus) 
	{
		let save_status = this.selectedType().statuses.find(status => status.status === currentStatus);

		let delivery_counts_valid;
		if (save_status.confirm_sent == true)
			delivery_counts_valid = await this.page.validate_delivery_counts('send');
		if (save_status.confirm_received == true)
			delivery_counts_valid = await this.page.validate_delivery_counts('receive');
		
		if (delivery_counts_valid || (!save_status.confirm_sent && !save_status.confirm_received))
		{
			this.selectedStatus(save_status);
			this.page.save_order();
		}
	}

	async btn_delete_order_click ()
	{
		let confirm = await Grape.alerts.confirm({ message: 'Are you sure you want to delete this order?', title: 'Warning', type: 'warning' });

		if (!confirm)
			return;

		let result = await Grape.fetches.deleteJSON('/api/stock-management/order', { order_id: this.order_id() });
		if (result.status == 'OK')
		{
			await Grape.alerts.alert({ type: 'success', title: 'Delete order', message: 'Order successfully deleted'})
			Grape.navigate('/stock/order/all_orders/');
		}
		else
		{
			let message = result.message || result.detail;
			Grape.alerts.alert({ title: 'Error', type: 'error', message: message });
		}
	}

	async btn_delete_shipment_click (transaction)
	{
		let confirm = await Grape.alerts.confirm({ message: 'Are you sure you want to delete this shipment?', title: 'Warning', type: 'warning' });

		if (!confirm)
			return;

		try
		{
			let result = await Grape.fetches.deleteJSON('api/stock-management/order-transaction', { 
				order_id: this.order_id(),
				order_transaction_id: transaction.order_transaction_id
			});
			
			if (result.status != 'ERROR')
			{
				Grape.alerts.alert({ type: 'success', title: 'Success', message: 'Successfully deleted order transaction!' });
				await this.page.update_data();
			}
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}

	btn_edit_field_click (item) 
	{
		item.editing(true);
	}

	btn_back_click () 
	{
		if (this.selectedType() === undefined || this.selectedType() === null)
			Grape.navigate('/stock/order/all_orders/');
		else if (this.selectedType().type === 'Inter-Branch Transfer')
			Grape.navigate('/stock/order/ibt/');
		else if (this.selectedType().type === 'Sales Order')
			Grape.navigate('/stock/order/sales_orders/');
		else if (this.selectedType().type === 'Purchase Order')
			Grape.navigate('/stock/order/purchase_orders/');
		else if (this.selectedType().type === 'Usage Order')
			Grape.navigate('/stock/order/usage_orders/');
		else
			Grape.navigate('/stock/order/all_orders/');
	}

	btn_save_field_click (item) 
	{
		item.editing(false);

		if (this.type() !== 'create')
			this.page.save_order();
	}

	btn_save_click () 
	{
		this.page.save_order();
	}

	btn_create_click () 
	{
		this.page.save_order();
	}

	btn_download_document_click (item) 
	{
		if (navigator.userAgent.indexOf("Chrome") !== -1)
			this.open_pdf_in_new_window(item);
		else 
		{
			let document = window.open(`/api/stock-management/order/document?order_id=${this.order_id()}&document_name=${item.name}`);
			document.onload = (() => { this.page.update_data(); });
		}
	}

	async open_pdf_in_new_window(item) 
	{
		try 
		{
			const response = await fetch(`/api/stock-management/order/document?order_id=${this.order_id()}&document_name=${item.name}`);
		
			if (!response.ok)
				throw new Error('Network response was not ok');
		
			const blob = await response.blob();
			const blob_url = window.URL.createObjectURL(blob);
			const new_window = window.open('', '_blank');

			if (new_window) 
			{
				new_window.document.write(`<iframe width='100%' height='100%' src='${blob_url}'></iframe>`);
				new_window.document.title = 'Document';
				new_window.focus();
			} else
				throw new Error('Unable to open new window. Please check your pop-up blocker settings.');
		} catch (error) {
			console.error('Error:', error);
			Grape.alerts.alert({ title: 'Error', type: 'error', message: error.message });
		} finally {
			this.page.update_data();
		}
	}

	async btn_add_partial_delivery_click (receive) 
	{
		let response = await Grape.dialog.open('PartialDeliveryDialog', { order_id: this.order_id(), order: this.order(), receive: receive});

		if (response)
			await this.page.update_data();
	}

	async btn_view_transaction_click (item) 
	{
		await Grape.dialog.open('ViewTransactionDialog', { order_id: this.order_id(), order_transaction_id: item.order_transaction_id });
	}

	find_stock_item_id (description) 
	{
		let foundItem = this.stock_items().find(item => item.description === description);

		return foundItem ? foundItem.stock_item_id : null;
	}
	
	current_status_document (item) 
	{
		if (this.selectedStatus() && this.selectedStatus().document_name)
			return this.selectedStatus().document_name === item.name;

		return false;
	}

	async delete_delivery_doc_click (doc)
	{
		try 
		{
			let confirm = await Grape.alerts.confirm({ message: 'Are you sure you want to delete this document?', title: 'Warning', type: 'warning' });

			if (!confirm)
				return;

			let response = await fetch(`/api/order-delivery/doc?order_delivery_document_id=${doc.order_delivery_document_id}`, {
				headers: {'Content-Type': 'application/json'},
				method: 'DELETE'
			});

			let data = await response.json();
			if (data.status == 'OK') 
				Grape.alerts.alert({ type: 'success', title: 'Delete delivery document', message: 'Delivery document was deleted successfully'})
			else
				throw new Error(data.message || 'Error deleting delivery document');
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		} finally {
			this.page.update_data();
		}
	}

	async upload_delivery_doc_click () 
	{
		document.querySelector('.order_document_upload').click();
	}

	add_reference_number (value, event)
	{
		if (event.keyCode !== 13 && (event.type !== 'blur'))
			return true;

		if (this.new_reference_number())
		{
			if (!this.reference_numbers())
				this.reference_numbers([]);
			this.reference_numbers.push(this.new_reference_number());
			this.new_reference_number("");
		}

		return true;
	}

	remove_reference_number (value)
	{
		this.reference_numbers.splice(this.reference_numbers.indexOf(value), 1);
	}

	download_delivery_doc_click (document)
	{
		window.open(`/delivery/document/download/${document.order_delivery_document_id}`);
	}

	update_order_items_type()
	{
		let type = this.type();

		if (type == 'edit' && this.order().confirmed)
			type = 'view';

		if (this.is_superuser() && !this.order().completed && !this.order().cancelled)
			type = 'edit';

		this.order_items_type(type);
	}

	can_edit_tax () 
	{
		let allowed = true;

		if (Grape.currentSession.roles.includes('stock.all-location-permissions'))
			allowed = true;
		else
		{
			let selected_source = this.selectedSource();
			if (selected_source)
				if (!this.page.creatable_locations.find(cloc => cloc.location_id == selected_source.location_id))
					allowed = false;

			if (allowed)
			{
				let selected_destination = this.selectedDestination();
				if (selected_destination)
					if (!this.page.creatable_locations.find(cloc => cloc.location_id == selected_destination.location_id))
						allowed = false;
			}
		}
		return allowed;
	}

	can_progress_order () 
	{
		let allowed = true;

		if (Grape.currentSession.roles.includes('stock.all-location-permissions'))
			allowed = true;
		else
		{
			if (this.selectedSource())
				if (!this.page.confirmable_locations.find(cloc => cloc.location_id == this.selectedSource().location_id))
					allowed = false;

			if (allowed)
				if (this.selectedDestination())
					if (!this.page.confirmable_locations.find(cloc => cloc.location_id == this.selectedDestination().location_id))
						allowed = false;
		}

		return allowed;
	}

	is_superuser ()
	{
		let allowed = false;

		if ((this.type() == 'edit' || this.type() == 'view') && Grape.currentSession.roles.includes('stock.super_user'))
			allowed = true;

		return allowed;
	}

	can_view_location ()
	{
		if (this.filtered_locations().find(location => location.location_id === this.selectedDestination().location_id))
			return true;
		else
			return false;
	}
}

class CreateOrderPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new CreateOrderViewModel(this);
		this.confirmable_locations = [];
		this.creatable_locations = [];
		this.all_locations = [];
	}

	async init () 
	{
		document.title = 'Create Order';
		this.viewModel.order_date(moment().format('YYYY-MM-DD'));
		this.viewModel.delivery_date(moment().add(7, 'days').format('YYYY-MM-DD'));

		try 
		{
			let [locations, all_locations, type, stock] = await Promise.all([
				Grape.cache.fetch(this.viewModel.type() == "create" ? 'ActiveLocations' : 'Locations'),
				Grape.cache.fetch(this.viewModel.type() == "create" ? 'AllActiveLocations' : 'AllLocations'),
				Grape.cache.fetch('OrderTypes'),
				Grape.fetches.getJSON('/api/record', { 
					table: 'v_stock_item', 
					schema: 'stock', 
					limit: 10000, 
					fields: ['stock_item_id', 'description', 'attributes', 'in_use', 'location_ids'],
					filter: this.viewModel.type() == 'create' ? [
						{
							field: 'in_use',
							operand: '=',
							value: true
						}
					] : []
				})
			]);
			this.viewModel.locations(all_locations);

			this.all_locations = all_locations;
			this.creatable_locations = await window.Grape.StockUtils.get_user_locations('CreateOrder');

			// TODO update or create helper function
			let filtered_locations = [];
			locations.forEach((loc) => {
				if (Grape.currentSession.roles.includes('stock.all-location-permissions')
					|| (this.creatable_locations.find(cloc => cloc.location_id == loc.location_id))
					|| this.viewModel.type() == 'view')
					filtered_locations.push(loc);
			});
			this.confirmable_locations = await window.Grape.StockUtils.get_user_locations('ConfirmOrder');
	
			this.viewModel.filtered_locations(filtered_locations);
			
			let order_types = Object.values(type).filter((o_type) => !o_type.batch_created && o_type.type !== 'Production Order' && o_type.type !== 'Work Order');
			this.viewModel.order_types(order_types);

			if (this.bindings.order_type) 
			{
				let matching_type = this.viewModel.order_types().find(order_type => order_type.type === this.bindings.order_type);
				if (matching_type) 
				{
					this.viewModel.is_type_from_list(true);
					this.viewModel.selectedType(matching_type);
				}
			}

			this.viewModel.stock_items(stock.records);
	
			if (this.bindings.type == 'edit' || this.bindings.type == 'view')
				await this.update_data();
			else if (this.bindings.supplier_name !== undefined)
				this.viewModel.selectedSource(this.populate_option_field(this.viewModel.filtered_locations(), this.bindings.supplier_name) || '')
		} catch (error) {
			Grape.alerts.alert({ title: 'Error', type: 'error', message: error.message });
			console.error('Error fetching data:', error);
		}

		// Register file upload event listener
		const hiddenFileInput = document.querySelector('.order_document_upload');
		hiddenFileInput.addEventListener('change', async () => {
			const selectedFile = hiddenFileInput.files[0]; // Get the selected file

			if (selectedFile)
			{
				let header_info = { 
					order_id: this.viewModel.order_id(),
					"X-SessionID": localStorage.getItem('session_id'),
					"Accept": "application/json"
				};

				//SERVER: Upload file(s)
				try
				{
					let response = await fetch('/api/order-delivery/doc/upload', {
						method: 'POST',
						body: new FormData(document.getElementById('delivery_upload_doc')),
						headers: header_info
					});

					let data = await response.json();
					if (response.ok) 
						Grape.alerts.alert({ type: 'success', title: 'Success', message: 'File Successfully Uploaded'});
					else 
						throw new Error(data.message);
				} catch (exception) {
					Grape.alerts.alert({ type:'warning', message: exception, title:`File Upload Error` });
					console.error(exception);
				} finally {
					this.update_data();
				}
			}
		});
	}

	async populate_supplier_stock(supplier)
	{
		if (this.viewModel.selectedType() && this.viewModel.selectedType().source_location_type === 'Supplier')
		{
			let filter = [{
					field: 'location_ids',
					operand: '@>',
					value: [supplier.location_id]
				}];

			if (this.viewModel.type() == 'create')
				filter.push({
					field: 'in_use',
					operand: '=',
					value: true
				});

			let supplier_stock = await Grape.fetches.getJSON('/api/record', { 
				table: 'v_stock_item', 
				schema: 'stock', 
				limit: 10000, 
				fields: ['stock_item_id', 'description', 'attributes', 'in_use', 'location_ids'],
				filter: filter
			})

			this.viewModel.stock_items(supplier_stock.records);
		}
	}
	
	async update_data () 
	{
		try 
		{
			this.viewModel.loading(true);
			
			let audit;
			if (this.viewModel.type() == 'edit' || this.viewModel.type() == 'view')
			{
				audit = await Grape.fetches.getJSON('/api/record', {
					table: 'v_order_audit_log',
					schema: 'stock',
					filter: [{
						field: 'order_id',
						operand: '=' ,
						value: this.viewModel.order_id()
					}],
					sortorder: 'DESC',
					sortfield: 'date_inserted'
				})

				if (audit.status == 'OK')
				{
					audit.records.forEach(record => record.date_inserted = moment(record.date_inserted).format('YYYY-MM-DD, HH:mm') );
					this.viewModel.order_audit_log(audit.records);
				} else 
					throw new Error(audit.message || audit.code);
			}

			let deliveryDocs = await Grape.fetches.getJSON(`/api/order-delivery/doc?order_id=${this.viewModel.order_id()}`);

			if (deliveryDocs.status == 'OK')
				this.viewModel.delivery_docs(deliveryDocs.documents || []);
			else
				throw new Error(deliveryDocs.message || deliveryDoc.code || 'Error fetching delivery docs');

			let orderData = await Grape.fetches.getJSON(`/api/stock-management/order`, { order_id: this.viewModel.order_id() });

			if (orderData.status === 'OK') 
			{
				let order = orderData.order;
				if (order.source_location && !this.populate_option_field(this.viewModel.filtered_locations(), order.source_location))
					this.viewModel.filtered_locations.push(this.populate_option_field(this.all_locations, order.source_location));
				if (order.target_location && !this.populate_option_field(this.viewModel.locations(), order.target_location))
					this.viewModel.locations.push(this.populate_option_field(this.all_locations, order.target_location));

				if (Grape.currentSession.roles.includes('stock.super_user') || !order.confirmed)
					this.viewModel.can_reject_order(true);
				else
					this.viewModel.can_reject_order(false);

				this.viewModel.order(orderData.order);
				this.viewModel.selectedType(this.populate_option_field(this.viewModel.order_types(), order.order_type));
				this.viewModel.selectedStatus(this.populate_option_field(this.viewModel.order_statuses(), order.order_status));

				if (this.viewModel.selectedStatus().final === true) 
					if (this.viewModel.type() == 'edit')
						Grape.navigate(`/stock/order/view/${this.viewModel.order_id()}`);

				this.viewModel.acceptStatus(this.viewModel.selectedStatus().accept_status);
				this.viewModel.rejectStatus(this.viewModel.selectedStatus().reject_status);
				this.viewModel.selectedSource(this.populate_option_field(this.viewModel.filtered_locations(), order.source_location));
				this.viewModel.order_date(order.order_date);
				this.viewModel.delivery_date(order.delivery_date);
				this.viewModel.reference_numbers(order.reference_numbers);
				this.viewModel.order_number(order.order_nr);
				this.viewModel.order_note(order.note);

				if ((order.target_location != null) || (order.target_location != undefined))
					this.viewModel.selectedDestination(this.populate_option_field(this.viewModel.locations(), order.target_location));

				let initial_selected_items = [];
				if (this.viewModel.stock_order_items().length > 0)
					this.viewModel.stock_order_items.removeAll();

				order.items.forEach(item => {
					this.viewModel.stock_order_items.push({
						stock_item_id: item.stock_item_id,
						order_item_id: item.order_item_id,
						description: item.description,
						ppu: ko.observable(item.ppu),
						qty: ko.observable(item.qty),
						qty_received: item.qty_received,
						qty_sent: item.qty_sent,
						delivery_date: ko.observable(item.delivery_date),
						expected_receive_dates: ko.observableArray(item.expected_receive_dates.map(date => ({
							date: ko.observable(date.date),
							qty: ko.observable(parseInt(date.qty))
						})))
					});

					initial_selected_items.push(item);
				});
				this.viewModel.initial_selected_items(initial_selected_items);

				if (order.documents != undefined) 
				{
					let order_documents = [];
					order.documents.forEach(document => {
						order_documents.push({
							order_document_id: document.order_document_id,
							path_basename: document.path_basename,
							name: document.name,
							user_id: document.username,
							date_inserted: new Date(document.date_inserted).toISOString().split('T')[0]
						});
					});
					this.viewModel.order_documents(order_documents);
				}

				if (order.transactions != undefined) 
				{
					let order_transactions = [];
					order.transactions.forEach(transaction => {

						// Make qty positive in all cases
						let pos_qty = transaction.qty < 0 ? -transaction.qty : transaction.qty;

						order_transactions.push({
							user_created: transaction.user_created,
							committed: transaction.committed,
							date_effective: transaction.date_effective,
							movement_subtype: transaction.movement_subtype,
							movement_type: transaction.movement_type,
							note: transaction.note,
							receive: transaction.receive,
							user_committed: transaction.user_committed,
							user_inserted: transaction.user_inserted,
							target_location: order.target_location,
							source_location: order.source_location,
							order_transaction_id: transaction.order_transaction_id,
							qty: pos_qty
						})
					});
					this.viewModel.order_transactions(order_transactions);
				}

				if (order.pricing) 
				{
					let order_pricing = [];
					order_pricing.push({
						total_value: order.pricing.total_value,
						total_after_order_taxes: order.pricing.total_after_order_taxes,
						taxes: order.pricing.taxes.map(tax => ({name: tax.name, percent: tax.percent})),
						price_adjustments: order.pricing.price_adjustments,
						isEditing: ko.observable(false)
					});
					this.viewModel.order_pricing(order_pricing)
				}

				if (order.fields != undefined) 
				{
					let order_fields = [];
					Object.entries(order.fields).forEach(([key, value]) => {
						order_fields.push({
							fieldName: key,
							data: value,
							editing: ko.observable(false)
						});
					});
					this.viewModel.order_fields(order_fields);
				}
				document.title = this.viewModel.order_number();
				this.viewModel.loading(false);
			}
			else
				throw new Error(orderData.message || orderData.code);

			this.viewModel.update_order_items_type();
		} catch (error) {
			Grape.alerts.alert({ title: 'Error', type: 'error', message: error.message });
			console.error('Error:', error);
			this.viewModel.loading(false);
		}
	}

	populate_option_field (optionsArray, optionValue)
	{
		if (optionValue === undefined)
			return undefined;

		return optionsArray.find(option => 
			option.type === optionValue || 
			option.status === optionValue || 
			option.name === optionValue
		);
	}

	async save_order ()
	{
		this.viewModel.loading(true);

		if (!this.validate_order())
		{
			this.viewModel.loading(false);
			return;
		}

		let order = {
			delivery_date: this.viewModel.delivery_date(),
			reference_numbers: this.viewModel.reference_numbers() || [],
			order_date: this.viewModel.order_date(),
			type: this.viewModel.selectedType().type,
			status: this.viewModel.selectedStatus().status,
			source_location: this.viewModel.selectedSource().name,
			target_location: this.viewModel.selectedDestination() ? this.viewModel.selectedDestination().name : null,
			order_nr: this.viewModel.order_number(),
			note: this.viewModel.order_note(),
			taxes: this.viewModel.edited_tax(),
			items: this.viewModel.stock_order_items().map(item => {
				return {
					description: item.description,
					stock_item_id: this.viewModel.find_stock_item_id(item.description),
					qty: item.expected_receive_dates().reduce((sum, delivery) => sum + parseInt(delivery.qty(), 10), 0),
					ppu: item.ppu(),
					expected_receive_dates: item.expected_receive_dates().map(delivery => {
						return {
							date: delivery.date(),
							qty: delivery.qty()
						};
					})
				};
			})
		};

		if (this.viewModel.type() == 'edit' || this.viewModel.type() == 'view')
			order.order_id = this.viewModel.order_id();

		let fields = {};
		for (let item of this.viewModel.order_fields())
			fields[item.fieldName] = item.data;
		order.fields = fields;

		try
		{
			if (this.viewModel.type() === 'create')
			{
				let response = await Grape.dialog.open('OrderConfirm', { order: order });
	
				if (response)
				{
					let result = await Grape.fetches.postJSON('/api/stock-management/order', order);
	
					if (result.status == 'OK')
						Grape.navigate(`/stock/order/edit/${result.order_id}`);
					else
						throw new Error(result.message || result.code);
				}
			}
			else
			{
				let result = await Grape.fetches.postJSON('/api/stock-management/order', order);
	
				if (result.status == 'OK')
					this.update_data();
				else
					throw new Error(result.message || result.code);
			}

			this.viewModel.loading(false);

		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error('Error:', error);
		}
	}

	validate_order () 
	{
		try 
		{
			if (!this.viewModel.selectedType() || !this.viewModel.selectedStatus() || !this.viewModel.selectedSource() || 
			(this.viewModel.selectedType().target_location_type && !this.viewModel.selectedDestination()) || 
			!(this.viewModel.stock_order_items() && this.viewModel.stock_order_items().length)) 
				throw new Error('Please fill in all the fields & add at least one stock item to the table before saving.')
		
			this.viewModel.stock_order_items().forEach(item => {
				let total_qty = item.expected_receive_dates().reduce((sum, delivery) => sum + parseInt(delivery.qty(), 10), 0);
				item.expected_receive_dates().forEach(delivery => { 
					if (isNaN(delivery.qty()) || delivery.qty() === '')
						throw new Error('Item order quantity should be a number'); 
					if (delivery.qty() < 0) 
						throw new Error('Item order quantity should not be negative'); 
				});
				if (total_qty && total_qty < 0)
					throw new Error('Order quantity must be more than zero');
			});
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
			this.viewModel.loading(false);
			
			return false;
		}

		return true;
	}

	// Get current_price for item at selected source location
	async get_order_item_pricing () 
	{
		try 
		{
			if (this.viewModel.selectedSource())
			{
				let pricing = await Grape.fetches.getJSON('/api/record', {
					table: 'v_location_stock_item_info',
					schema: 'stock',
					fields: [ 'location_id', 'stock_item_id', 'current_price' ],
					filter: [{
						field: 'location_id',
						value: this.viewModel.selectedSource().location_id,
						operand: '='
					}]
				});

				if (pricing.status == 'OK')
				{
					let map = {};
					pricing.records.forEach(item => map[item.stock_item_id] = item.current_price );
				
					this.viewModel.stock_items().forEach(item => {
						let price = map[item.stock_item_id];
						if (price !== undefined)
							item.current_price = price;
					});
				}
				else
					throw new Error(pricing.message || pricing.code);
			}
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}

	async validate_delivery_counts (type)
	{
		let complete = true;
		this.viewModel.stock_order_items().forEach(item => {
			let outstanding_send_qty = item.expected_receive_dates().reduce((acc, curr) => acc + (parseInt(curr.qty()) || 0), 0) - (item.qty_sent && item.qty_sent !== null ? item.qty_sent : 0) || 0; 
			let outstanding_receive_qty  = item.expected_receive_dates().reduce((acc, curr) => acc + (parseInt(curr.qty()) || 0), 0) - (item.qty_received && item.qty_received !== null ? item.qty_received : 0) || 0;

			if (type === 'send' && outstanding_send_qty > 0)
				complete =  false;
			else if (type === 'receive' && outstanding_receive_qty > 0)
				complete = false;
		});

		if (!complete)
			if (await Grape.alerts.confirm({ message: `There are still outstanding ${type} deliveries, are you sure the order should be progressed to the next status?`, title: 'Warning', type: 'warning' }))
				complete = true;

		return complete;
	}
}

export default {
	route: '/stock/order/:type/:order_id',
	page_class: CreateOrderPage,
	template: template
}
