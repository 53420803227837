import template from './l_movement_page.html';
import LocationsViewAPI from './../location_view_api.js';

class LocationMovementViewModel
{
	constructor (page)
	{
		this.loaded = ko.observable(false);
		this.page = page;
		this.location_id = ko.observable(page.bindings.location_id);
	}
}

class LocationMovementPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new LocationMovementViewModel(this);
		this.LocationsViewAPI = new LocationsViewAPI(this.viewModel.location_id());
	}

	async init ()
	{
		document.title = 'Location Movements';
	}

}

export default {
	route: '[/location/]l_movements',
	page_id: 'l_movements',
	page_class: LocationMovementPage,
	template: template
};