import template from './ko-schedule-details.html';

class ScheduleDetailsComponentViewModel
{
	constructor (params)
	{
		this.params = params;
		this.order_id = ko_helper.safe_observable(params.order_id || null);
		this.reload_production_schedule = ko_helper.safe_observable(params.reload_production_schedule || null);
		
		this.table_records = ko.observableArray([]);
		this.total_duration = ko.observable();

		this.order_id.subscribe((newVal) => {
			if (newVal && newVal !== '' && newVal !== null)
				this.load_production_schedule();
		});

		this.reload_production_schedule.subscribe((newVal) => {
			if (newVal && newVal !== '' && newVal !== null)
				this.load_production_schedule();
		});
	}

	async load_production_schedule ()
	{
		try 
		{
			if (this.order_id() === null || this.order_id() === '')
				return;

			let response = await Grape.fetches.getJSON('/api/record', {
				table: 'v_production_schedule_details',
				schema: 'stock',
				filter: [{
					field: 'production_order_id',
					operand: '=',
					value: this.order_id()
				}],
				sortfield: 'step_nr',
				sortorder: 'ASC'
			});

			if (response.status === 'OK')
				this.table_records(response.records);
			else
				throw new Error(response.message || response.code);
		} 
		catch (error) 
		{
			Grape.alerts.alert({ title: 'Error', type: 'error', message: error.message });
			console.error('Error:', error);
		}
	}
}

export default {
	name: 'ko-schedule-details',
	viewModel: ScheduleDetailsComponentViewModel,
	module_type: 'ko',
	template: template
}