import template from './all_orders.html';

class AllOrdersViewModel
{
	constructor(page)
	{
		this.page = page;
		this.loading = ko.observable(false);
		this.orders = ko.observableArray([]);

		// Sort
		this.sortField = ko.observable('order_date');
		this.sortOrder = ko.observable('DESC');

		// Pagination
		this.current_page_number = ko.observable(1);
		this.current_page_size = ko.observable(20);
		this.page_count = ko.observable(1);
		this.pagination_summary = ko.observable('');
		this.number_of_records = ko.observableArray([20, 40, 100]);
		this.selected_number_of_records = ko.observable(20);

		// Filters
		this.selected_types = ko.observableArray(JSON.parse(localStorage.getItem('selected_types_all_orders')) || []);
		this.selected_statuses = ko.observableArray(JSON.parse(localStorage.getItem('selected_statuses_all_orders')) || []);
		this.selected_sources = ko.observableArray(JSON.parse(localStorage.getItem('selected_sources_all_orders')) || []);
		this.selected_destinations = ko.observableArray(JSON.parse(localStorage.getItem('selected_destinations_all_orders')) || []);
		this.selected_from_date = ko.observable(localStorage.getItem('selected_from_date_all_orders') || null);
		this.selected_to_date = ko.observable(localStorage.getItem('selected_to_date_all_orders') || null);
		this.display_completed_selected = ko.observable(localStorage.getItem('display_completed_all_orders') || 'No');
		this.display_cancelled_selected = ko.observable(localStorage.getItem('display_cancelled_all_orders') || 'No');
		this.search_string = ko.observable(localStorage.getItem('search_string_all_orders') || '');

		this.available_types = ko.observableArray([]);
		this.available_locations = ko.observableArray([]);
		this.available_statuses = ko.observableArray([]);
		this.display_completed_options = ['Yes', 'No'];
		this.display_cancelled_options = ['Yes', 'No'];
		this.current_options = ko.observableArray();
		this.creatable_locations = [];
		this.batch_order_types = ko.observable();
		this.selected_batch_order_type = ko.observable();
		this.creating_batch = ko.observable(false);
		this.use_custom_batch_import = ko.observable(false);
		this.batch_order_ids = ko.observableArray([]);

		this.display_completed_selected.subscribe(() => {
			if (this.page.initialized)
				this.page.updateData();
		});
		this.display_cancelled_selected.subscribe(() => {
			if (this.page.initialized)
				this.page.updateData();
		});

		this.selected_from_date.subscribe(() => {
			if (this.page.initialized)
				this.page.updateData();
		});
		this.selected_to_date.subscribe(() => {
			if (this.page.initialized)
				this.page.updateData();
		});
		this.selected_destinations.subscribe(() => {
			if (this.page.initialized)
				this.page.updateData();
		});
		this.selected_sources.subscribe(() => {
			if (this.page.initialized)
				this.page.updateData();
		});
		this.selected_statuses.subscribe(() => {
			if (this.page.initialized)
				this.page.updateData();
		});
		this.selected_types.subscribe(() => {
			if (this.page.initialized)
				this.page.updateData();
		});

		this.selected_number_of_records.subscribe((newVal) => {
			localStorage.setItem('selected_number_of_records_all_orders', this.selected_number_of_records());
			this.current_page_size(newVal);
			if (this.page.initialized)
				this.page.updateData();
		});

		this.selected_batch_order_type.subscribe(() => {
			this.batch_order_ids([]);
			if (this.page.initialized)
				this.page.updateData();
		});

		this.subscribe_to_filters();
	}

	subscribe_to_filters ()
	{
		this.selected_types.subscribe((newVal) => localStorage.setItem('selected_types_all_orders', JSON.stringify(newVal)));
		this.selected_statuses.subscribe((newVal) => localStorage.setItem('selected_statuses_all_orders', JSON.stringify(newVal)));
		this.selected_sources.subscribe((newVal) => localStorage.setItem('selected_sources_all_orders', JSON.stringify(newVal)));
		this.selected_destinations.subscribe((newVal) => localStorage.setItem('selected_destinations_all_orders', JSON.stringify(newVal)));
		this.selected_from_date.subscribe((newVal) => localStorage.setItem('selected_from_date_all_orders', newVal));
		this.selected_to_date.subscribe((newVal) => localStorage.setItem('selected_to_date_all_orders', newVal));
		this.display_completed_selected.subscribe((newVal) => localStorage.setItem('display_completed_all_orders', newVal));
		this.display_cancelled_selected.subscribe((newVal) => localStorage.setItem('display_cancelled_all_orders', newVal));
		this.search_string.subscribe((newVal) => localStorage.setItem('search_string_all_orders', newVal));
	}

	async btn_clear_filters_click ()
	{
		this.selected_types([]);
		this.selected_statuses([]);
		this.selected_sources([]);
		this.selected_destinations([]);
		this.selected_from_date(null);
		this.selected_to_date(null);
		this.display_completed_selected('No');
		this.display_cancelled_selected('No');
		this.search_string('');

		await Promise.all([
			localStorage.removeItem('selected_types_all_orders'),
			localStorage.removeItem('selected_statuses_all_orders'),
			localStorage.removeItem('selected_sources_all_orders'),
			localStorage.removeItem('selected_destinations_all_orders'),
			localStorage.removeItem('selected_from_date_all_orders'),
			localStorage.removeItem('selected_to_date_all_orders'),
			localStorage.removeItem('display_completed_all_orders'),
			localStorage.removeItem('display_cancelled_all_orders'),
			localStorage.removeItem('search_string_all_orders')
		]);

		this.page.updateData();
	}

	btn_create_order_click ()
	{
		Grape.navigate('/stock/order/create/');
	}

	btn_create_batch_click ()
	{
		this.creating_batch(true);
	}

	btn_import_batch_click()
	{
		Grape.navigate('/stock/batch-import/');
	}

	async btn_save_batch_click ()
	{
		let batch_order_type = this.selected_batch_order_type();
		if (!batch_order_type)
		{
			Grape.alerts.alert({ type: 'error', title: 'Error', message: 'Please select a Batch Type' });
			return;
		}
		if (this.batch_order_ids().length == 0)
		{
			Grape.alerts.alert({ type: 'error', title: 'Error', message: 'Please select some Orders to batch' });
			return;
		}

		let order = {
			delivery_date: moment().format('YYYY-MM-DD'),
			order_date: moment().format('YYYY-MM-DD'),
			type: batch_order_type.type,
			child_order_ids: this.batch_order_ids()
		};

		try 
		{
			let result = await Grape.fetches.postJSON('/api/stock-management/batch-order', order);

			if (result.status == 'OK')
			{
				Grape.alerts.alert({ type: 'success', title: 'Success', message: 'Batch order created' });
				this.reset_batch_create();
			
				this.page.updateData();
				// TODO Navigate to batch and remove alert
			}
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}

	btn_cancel_batch_click ()
	{
		this.reset_batch_create();
	}

	reset_batch_create ()
	{
		this.creating_batch(false);
		this.selected_batch_order_type(null);
		this.batch_order_ids([]);
	}

	async btn_download_orders_click ()
	{
		let options = this.current_options();

		let today = moment().format('DD-MM-YYYY');
		let filename = `Orders List (${today})`;

		options.offset = 0;
		options.limit = 100000;

		let filter_values = {
			'Selected Type(s)': this.selected_types().length > 0 ? this.selected_types() : 'None',
			'Selected Status(es)': this.selected_statuses().length > 0 ? this.selected_statuses() : 'None',
			'Selected Source(s)': this.selected_sources().length > 0 ? this.selected_sources().map(src => src.name) : 'None',
			'Selected Destination(s)': this.selected_destinations().length > 0 ? this.selected_destinations().map(dest => dest.name) : 'None',
			'Start Date': this.selected_from_date() === undefined ? 'None' : this.selected_from_date(),
			'End Date': this.selected_to_date() === undefined ? 'None' : this.selected_to_date(),
			'Completed Orders': this.display_completed_selected(),
			'Cancelled Orders': this.display_cancelled_selected(),
			'Search String': this.search_string() === '' ? 'None' : this.search_string()
		};
		
		let params = { 
			options: options, 
			headers: [
				{ field: 'order_nr', title: 'Order Number' },
				{ field: 'order_date', title: 'Order Date' },
				{ field: 'order_type', title: 'Order Type' },
				{ field: 'linked_orders_count', title: 'Linked Orders' },
				{ field: 'status', title: 'Status' },
				{ field: 'source_location', title: 'Source' },
				{ field: 'target_location', title: 'Destination' },
				{ field: 'stock_item_count', title: 'Item Count' },
				{ field: 'total_qty', title: 'Total Qty' },
				{ field: 'total_qty_received', title: 'Qty Outstanding' },
				{ field: 'total_value', title: 'Total Value' }
			],
			filters: filter_values
		};

		let url = await Grape.fetches.buildURL(`download/record/${filename}/`, params);		
		window.open(url.href);
	}

	btn_view_order_click (order) 
	{
		if (order.order_type == 'Picking List')
			Grape.navigate(`/picking-list/order/view/${order.order_id}`);
		else if (order.order_type == 'Batched Sales Order')
			Grape.navigate(`/batched-sales/order/view/${order.order_id}`);
		else if (order.order_type == 'Work Order')
			Grape.navigate(`/work/order/view/${order.order_id}`);
		else if (order.order_type == 'Production Order')
			Grape.navigate(`/production/order/view/${order.order_id}`);
		else
			Grape.navigate(`/stock/order/view/${order.order_id}`);
	}

	btn_edit_order_click (order) 
	{
		if (order.order_type == 'Picking List')
			if (this.page.can_capture_order(order))
				Grape.navigate(`/picking-list/order/edit/${order.order_id}`);
			else
				Grape.navigate(`/picking-list/order/view/${order.order_id}`);
		else if (order.order_type == 'Batched Sales Order')
			if (this.page.can_capture_order(order))
				Grape.navigate(`/batched-sales/order/edit/${order.order_id}`);
			else
				Grape.navigate(`/batched-sales/order/view/${order.order_id}`);
		else if (order.order_type == 'Work Order')
			if (this.page.can_capture_order(order))
				Grape.navigate(`/work/order/edit/${order.order_id}`);
			else
				Grape.navigate(`/work/order/view/${order.order_id}`);
		else if (order.order_type == 'Production Order')
			if (this.page.can_capture_order(order))
				Grape.navigate(`/production/order/edit/${order.order_id}`);
			else
				Grape.navigate(`/production/order/view/${order.order_id}`);
		else
			if (this.page.can_capture_order(order))
				Grape.navigate(`/stock/order/edit/${order.order_id}`);
			else
				Grape.navigate(`/stock/order/view/${order.order_id}`);
	}

	page_click (page_number)	
	{
		this.current_page_number(page_number);
		this.page.updateData();
	}

	btn_search_click() 
	{
		this.page.updateData();
	}

	sortClick (field)
	{
		this.sortField(field);

		if (this.sortOrder() == 'ASC')
			this.sortOrder('DESC');
		else
			this.sortOrder('ASC');

		this.page.updateData();
	}
}

class AllOrdersPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new AllOrdersViewModel(this);
		this.initialized = false;

		const searchInput = document.getElementById("searchInput");
		searchInput.addEventListener("keyup", (event) => {
			if (event.key === "Enter")
				this.updateData();
		});

		let selected_number_of_records_orders = localStorage.getItem('selected_number_of_records_all_orders');
		if (selected_number_of_records_orders)
		{
			this.viewModel.selected_number_of_records(selected_number_of_records_orders);
			this.viewModel.current_page_size(selected_number_of_records_orders);
		}
	}

	async init ()
	{
		this.viewModel.loading(true);
		document.title = 'All Orders';

		this.viewModel.use_custom_batch_import(Grape.config.public_settings.custom_batched_sales_import);

		let [locations, types, statuses] = await Promise.all([
			Grape.cache.fetch('Locations'),
			Grape.cache.fetch('OrderTypes'),
			Grape.cache.fetch('OrderStatuses'),
			Promise.resolve({ records: [] })
		]);

		this.viewModel.available_locations(locations);
		this.viewModel.creatable_locations = await window.Grape.StockUtils.get_user_locations('CreateOrder');

		this.viewModel.available_types(Object.keys(types));
		let batch_order_types = Object.values(types).filter((o_type) => o_type.batch_created);
		this.viewModel.batch_order_types(batch_order_types);

		let unique_statuses = [...new Set(statuses.map(record => record.status))];
		this.viewModel.available_statuses(unique_statuses);
		this.viewModel.loading(false);

		this.initialized = true;
	}

	async updateData()
	{
		this.viewModel.loading(true);
		let options = {
			table: 'v_orders',
			schema: 'stock',
			offset: 0,
			sortorder: this.viewModel.sortOrder(),
			sortfield: this.viewModel.sortField(),
			filter_join: 'AND',
			join: 'OR',
			filter: []
		}

		let filter_base = [];

		// LOGIC: filter 
		if (this.viewModel.selected_types().length > 0)
			filter_base.push({
				field: 'order_type',
				operand: 'IN',
				value: this.viewModel.selected_types()
			});

		if (this.viewModel.selected_statuses().length > 0)
			filter_base.push({
				field: 'status',
				operand: 'IN',
				value: this.viewModel.selected_statuses()
			});

		if (this.viewModel.selected_sources().length > 0)
			filter_base.push({
				field: 'source_location',
				operand: 'IN',
				value: this.viewModel.selected_sources().map(src => src.name)
			});

		if (this.viewModel.selected_destinations().length > 0)
			filter_base.push({
				field: 'target_location',
				operand: 'IN',
				value: this.viewModel.selected_destinations().map(dest => dest.name)
			});

		if (this.viewModel.selected_from_date())
			filter_base.push({
				field: 'order_date',
				operand: '>=',
				value: this.viewModel.selected_from_date()
			});

		if (this.viewModel.selected_to_date())
			filter_base.push({
				field: 'order_date',
				operand: '<=',
				value: this.viewModel.selected_to_date()
			});

		if (this.viewModel.display_completed_selected() == 'No')
			filter_base.push({
				field: 'completed', 
				operand: '=', 
				value: 'false'
			});

		if (this.viewModel.display_cancelled_selected() == 'No')
			filter_base.push({
				field: 'cancelled', 
				operand: '=', 
				value: 'false'
			});

		// LOGIC: Search
		if (this.viewModel.search_string() && this.viewModel.search_string() != '')
		{
			options.filter_join = "OR";
			options.filter = [
				{
					join: 'AND',
					filter: [
						{ 
							field: 'order_nr', 
							operand: 'ILIKE', 
							value: `%${this.viewModel.search_string()}%` 
						}
					].concat(filter_base)
				},
				{
					join: 'AND',
					filter: [
						{ 
							field: 'reference_numbers', 
							operand: 'ILIKE', 
							value: `%${this.viewModel.search_string()}%` 
						}
					].concat(filter_base)
				}
			]
		}
		else
			options.filter = filter_base;

		this.viewModel.current_options(options);

		// LOGIC: Pagination
		if (this.viewModel.current_page_number() && this.viewModel.current_page_size())
		{
			options.limit = this.viewModel.current_page_size();
			options.offset = (this.viewModel.current_page_number()-1) * this.viewModel.current_page_size();
		}
 
		try
		{
			let result = await Grape.fetches.getJSON('/api/record', options);

			if (result.status != 'ERROR')
			{
				result.records.forEach(order => {
					let order_nr_txt = order.order_nr;
					if (order.parent_order_id)
						order_nr_txt += ` (${order.parent_order_nr})`;

					order.order_nr_txt = order_nr_txt;

					order.batchable = false;
					let batch_order_type = this.viewModel.selected_batch_order_type();
					if (!order.parent_order_id && batch_order_type && batch_order_type.child_order_types.indexOf(order.order_type) > -1)
						order.batchable = true;
				});

				this.viewModel.orders(result.records);
				this.viewModel.page_count(Math.ceil(result.total/result.limit));
				this.viewModel.pagination_summary(`Showing ${options.offset} - ${this.viewModel.current_page_number() * this.viewModel.current_page_size()} of ${result.total} order(s)`);
			}
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
		this.viewModel.loading(false);
	}

	can_capture_order (order) 
	{
		let allow = false;

		if (order.source_location && order.target_location)
		{
			if (Grape.currentSession.roles.includes('stock.all-location-permissions')
				|| (
					(this.viewModel.creatable_locations.find(loc => loc.location_name == order.source_location))
					&& (this.viewModel.creatable_locations.find(loc => loc.location_name == order.target_location))
			))
				allow = true;
		}
		else if (order.source_location)
		{
			if ( Grape.currentSession.roles.includes('stock.all-location-permissions')
				|| (this.viewModel.creatable_locations.find(loc => loc.location_name == order.source_location)))
				allow = true;
		}
		else if (order.target_location)
		{
			if (Grape.currentSession.roles.includes('stock.all-location-permissions')
				|| (this.viewModel.creatable_locations.find(loc => loc.location_name == order.target_location)))
				allow = true;
		}

		return allow;
	}
}

export default {
	route: '/stock/order/all_orders/',
	page_class: AllOrdersPage,
	template: template
}
