import template from './order_types.html';

class OrderTypesPageViewModel 
{
	constructor (page) 
	{
		this.order_types = ko.observable({});

		this.order_type_selected = ko.observable(null);
		this.order_type_id = null;

		this.status_attributes = ['confirmation', 'is_send_complete_transaction', 'is_receive_complete_transaction', 'confirm_sent', 'confirm_received'];
		this.order_type_statuses = ko.observableArray([]);
		this.order_type_documents = ko.observableArray([]);
		this.cancel_status = ko.observable(null);
	}

	set_attributes(status)
	{
		let new_obj = {};
		this.status_attributes.forEach((x) => {
			if (status.attributes.indexOf(x) != -1)
				new_obj[x] = true;
			else
				new_obj[x] = false;
		});

		return new_obj;
	}

	get_attributes(status)
	{
		let attr = [];
		this.status_attributes.forEach((x) => {
			if (status[x])
				attr.push(x);
		});

		return attr;
	}

	order_type_click(data)
	{
		this.order_type_selected(data.type);
		this.order_type_id = data.order_type_id;

		// Build documents list
		let documents = [{order_type_document_id: null, name: 'None', template: 'None'}];
		data.statuses.forEach((x) => {
			if (x.document_template)
			{
				documents.push({order_type_document_id: x.order_type_document_id, name: x.document_name, template: x.document_template});
			}
		});
		this.order_type_documents(documents);

		let statuses = [];
		let attributes = [];

		let index = 0;
		let current_status = null;
		let document = null;
		let next_status = null;
		let done = false;

		// Find initial status
		current_status = data.statuses.find((x) => x.initial);
		attributes = this.get_attributes(current_status);
		document = documents.find((x) => x.order_type_document_id == current_status.order_type_document_id);

		statuses.push({index: index, name: current_status.status, attributes: attributes, selected_document: document});
		this.cancel_status(current_status.reject_status);

		// Populate the rest based on accept_status links
		while (!done) {
			next_status = undefined;
			index++;

			next_status = data.statuses.find((x) => x.status == current_status.accept_status);
			if (next_status === undefined || index >= data.statuses.length)
			{
				done = true;
			}
			else
			{
				current_status = next_status;
				attributes = this.get_attributes(current_status);
				document = documents.find((x) => x.order_type_document_id == current_status.order_type_document_id);

				statuses.push({index: index, name: current_status.status, attributes: attributes, selected_document: document});
			}
		}
		this.order_type_statuses(statuses);
	}

	async save_click()
	{
		// Build new statuses
		let statuses = ko.toJS(this.order_type_statuses());
		let new_statuses = [];
		for (let i = 0; i < statuses.length; i++)
		{
			let new_status = {};
			new_status.name = statuses[i].name;
			if (i < statuses.length - 1)
			{
				new_status.accept_status = statuses[i + 1].name
				new_status.reject_status = this.cancel_status();
			}
			new_status = Object.assign(new_status, this.set_attributes(statuses[i]));

			new_statuses.push(new_status);
		}

		try
		{
			// Get the counts of the the exiting statuses
			let response = await Grape.fetches.getJSON('/api/record', {schema: 'stock', table: 'v_orders_summary', filter: [{field: 'order_type', operand: '=', value: this.order_type_selected()}]});
			
			let transition_statuses = [];
			if (response.records.length > 0)
			{
				// Show dialog if some of them have to transition
				let dialog_data = await Grape.dialog.open('OrderStatuses', {old_statuses: response.records, new_statuses: new_statuses});
				transition_statuses = dialog_data.map((x) => { return {from_status: x.order_status, to_status: x.selected_status}; });
			}
			// Get documents
			let documents = [];

			statuses.forEach((x) => {
				if (x.selected_document.order_type_document_id !== null)
				{
					documents.push({order_type_document_id: x.selected_document.order_type_document_id, order_status: x.name});
				}
			});


			let data = {
				order_type_id: this.order_type_id,
				new_statuses: new_statuses,
				transition_statuses: transition_statuses,
				documents: documents
			};
			let result = await Grape.fetches.postJSON('/api/stock-management/order-status', data);

			if (result.status != 'OK')
			{
				await Grape.alerts.alert({ type: 'error', title: 'Error', message: result.message });
			}
			else
			{
				await Grape.alerts.alert({ type: 'success', title: 'Saved', message: 'The order statuses has been updated' });
				await this.update();
			}
		} catch (error) {
			console.log(error)
		}
	}

	move_up_click(data, event)
	{
		let statuses = this.order_type_statuses();
		let temp = statuses.splice(data.index - 1, 1, data);
		statuses.splice(data.index, 1, temp[0]);

		let i = 0;
		statuses.forEach(x => x.index = i++);
		this.order_type_statuses(statuses);
	}
	
	move_down_click(data, event)
	{
		let statuses = this.order_type_statuses();
		let temp = statuses.splice(data.index + 1, 1, data);
		statuses.splice(data.index, 1, temp[0]);

		let i = 0;
		statuses.forEach(x => x.index = i++);
		this.order_type_statuses(statuses);
	}

	add_click(data, event)
	{
		let statuses = this.order_type_statuses();
		statuses.push({
			index: statuses.length,
			name: null,
			attributes: [],
			selected_document: null
		});

		this.order_type_statuses(statuses);
	}

	remove_click(data, event)
	{
		let statuses = this.order_type_statuses();
		statuses.splice(data.index, 1);

		let i = 0;
		statuses.forEach(x => x.index = i++);
		this.order_type_statuses(statuses);
	}

	async update ()
	{
		Grape.cache.refresh('OrderTypes');
		this.order_types(await Grape.cache.fetch('OrderTypes'));
	}
}

class OrderTypesPage 
{
	constructor (bindings) 
	{
		this.bindings = bindings;
		this.viewModel = new OrderTypesPageViewModel(this);
		document.title = 'SystemSetup: Order Types';
	}

	async updateData ()
	{
		return this.viewModel.update();
	}
}

export default {                
	route: '[/]stock/admin/order-types',
	page_class: OrderTypesPage,
	template: template              
}
