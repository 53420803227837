import template from './order-list.html';

/**
* @class OrderListViewModel
* @constructor 
* @param {array[]} [available_items] - list of available stock items
* @param {array[]} selected_items - list of selected stock items
* @param {bool} [show_hidden_items=false] - shows active stock items (false) or all stock items (true)
* @param {text} [title] - title of the dropdown
*/
class OrderListViewModel
{
	constructor (params)
	{
		this.params = params;
		this.loading = ko.observable(false);
		this.initialized = ko.observable(false);
		this.orders = ko.observableArray([]);

		// Params
		this.type = ko_helper.safe_observable(params.type);
		this.show_destination = ko_helper.safe_observable(params.show_destination || false);
		this.show_source = ko_helper.safe_observable(params.show_source || false);
		this.show_supplier = ko_helper.safe_observable(params.show_supplier || false);

		// Prefix to use for localStorage
		this.ls_prefix = this.type() ? this.type().replace(/\s+/g, '') : 'Order';

		// Sort
		this.sortField = ko.observable('order_date');
		this.sortOrder = ko.observable('DESC');

		// Pagination
		this.current_page_number = ko.observable(1);
		this.current_page_size = ko.observable(10);
		this.page_count = ko.observable(1);
		this.pagination_summary = ko.observable('');
		this.number_of_records = ko.observableArray([20, 40, 100]);
		this.selected_number_of_records = ko.observable(20);

		// Filters
		this.selected_sources = ko.observableArray(JSON.parse(localStorage.getItem(this.ls_prefix + '_selected_sources')) || []);
		this.selected_destinations = ko.observableArray(JSON.parse(localStorage.getItem(this.ls_prefix + '_selected_destinations')) || []);
		this.selected_statuses = ko.observableArray(JSON.parse(localStorage.getItem(this.ls_prefix + '_selected_statuses')) || []);
		this.selected_from_date = ko.observable(localStorage.getItem(this.ls_prefix + '_selected_from_date') || '');
		this.selected_to_date = ko.observable(localStorage.getItem(this.ls_prefix + '_selected_to_date') || '');
		this.display_completed_selected = ko.observable(localStorage.getItem(this.ls_prefix + '_display_completed_selected') || 'No');
		this.display_cancelled_selected = ko.observable(localStorage.getItem(this.ls_prefix + '_display_cancelled_selected') || 'No');
		this.search_string = ko.observable(localStorage.getItem(this.ls_prefix + '_search_string') || '');

		this.available_locations = ko.observableArray([]);
		this.available_suppliers = ko.observableArray([]);
		this.available_statuses = ko.observableArray([]);
		this.display_completed_options = ['Yes', 'No'];
		this.display_cancelled_options = ['Yes', 'No'];
		this.currentOptions = ko.observableArray();
		this.creatable_locations = [];

		// DB-Saved Filters
		this.current_filter_name = ko.observable('');
		this.current_filter_id = ko.observable();
		this.current_filter_public = ko.observable(false);
		this.current_filter_criteria = ko.observableArray([]);
		this.filters_array = ko.observableArray([]);

		let selected_number_of_records_orders = localStorage.getItem(this.ls_prefix + '_selected_number_of_records');
		if (selected_number_of_records_orders)
		{
			this.selected_number_of_records(parseInt(selected_number_of_records_orders, 20));
			this.current_page_size(parseInt(selected_number_of_records_orders, 20));
		}

		this.subscribe_to_filters();

		const searchInput = document.getElementById("searchInput");
		searchInput.addEventListener("keyup", (event) => {
			if (event.key === "Enter")
				this.updateData();
		});

		this.init();
	}

	subscribe_to_filters ()
	{
		let prefix = this.ls_prefix;

		this.selected_sources.subscribe(newVal => {
			if (this.initialized())
			{
				localStorage.setItem(prefix + '_selected_sources', JSON.stringify(newVal));
				this.updateData();
			}
		});

		this.selected_destinations.subscribe(newVal => {
			if (this.initialized())
			{
				localStorage.setItem(prefix + '_selected_destinations', JSON.stringify(newVal));
				this.updateData();
			}
		});

		this.selected_statuses.subscribe(newVal => {
			if (this.initialized())
			{
				localStorage.setItem(prefix + '_selected_statuses', JSON.stringify(newVal));
				this.updateData();
			}
		});

		this.selected_from_date.subscribe(newVal => {
			if (this.initialized())
			{
				localStorage.setItem(prefix + '_selected_from_date', newVal || '');
				this.updateData();
			}
		});

		this.selected_to_date.subscribe(newVal => {
			if (this.initialized())
			{
				localStorage.setItem(prefix + '_selected_to_date', newVal || '');
				this.updateData();
			}
		});

		this.display_completed_selected.subscribe(newVal => {
			if (this.initialized())
			{
				localStorage.setItem(prefix + '_display_completed_selected', newVal);
				this.updateData();
			}
		});

		this.display_cancelled_selected.subscribe(newVal => {
			if (this.initialized())
			{
				localStorage.setItem(prefix + '_display_cancelled_selected', newVal);
				this.updateData();
			}
		});

		this.search_string.subscribe(newVal => {
			if (this.initialized())
				localStorage.setItem(prefix + '_search_string', newVal);
		});

		this.selected_number_of_records.subscribe(newVal => {
			localStorage.setItem(prefix + '_selected_number_of_records', newVal);
			this.current_page_size(newVal);
			if (this.initialized())
				this.updateData();
		});
	}

	btn_create_order_click ()
	{
		Grape.navigate('/stock/order/create/', { type: 'create', order_type: this.type() });
	}

	async btn_download_movements_click ()
	{ 
		let options = this.currentOptions();
		let today = moment().format('DD-MM-YYYY');
		let filename = `${this.type()} List (${today})`;

		options.offset = 0;
		options.limit = 1000;

		let filter_values = {
			'Selected Status(es)': this.selected_statuses().length > 0 ? this.selected_statuses() : 'None',
			'Selected Source(s)': this.selected_sources().length > 0 ? this.selected_sources().map(src => src.name) : 'None',
			'Selected Destination(s)': this.selected_destinations().length > 0 ? this.selected_destinations().map(dest => dest.name) : 'None',
			'Start Date': this.selected_from_date() === undefined ? 'None' : this.selected_from_date(),
			'End Date': this.selected_to_date() === undefined ? 'None' : this.selected_to_date(),
			'Completed Orders': this.display_completed_selected(),
			'Cancelled Orders': this.display_cancelled_selected(),
			'Search String': this.search_string() === '' ? 'None' : this.search_string()
		};

		let params = { 
			options: options, 
			headers: [
				{ field: 'order_nr', title: 'Order Number' },
				{ field: 'order_date', title: 'Order Date' },
				{ field: 'order_type', title: 'Order Type' },
				{ field: 'source_location', title: 'Source' },
				{ field: 'target_location', title: 'Destination' },
				{ field: 'status', title: 'Status' },
				{ field: 'stock_item_count', title: 'Item Count' },
				{ field: 'total_qty', title: 'Total Qty' },
				{ field: 'total_qty_received', title: 'Qty Outstanding' },
				{ field: 'total_value', title: 'Total Value' },
			],
			filters: filter_values
		};

		let url = await Grape.fetches.buildURL(`download/record/${filename}/`, params);		
		window.open(url.href);
	}

	btn_view_order_click (order)
	{
		Grape.navigate(`/stock/order/${order.cancelled || order.completed ? 'view' : 'edit'}/${order.order_id}`);
	}

	btn_edit_order_click (order)
	{
		if (this.type() === 'Batched Sales Order')
			if (this.can_capture_order(order))
				Grape.navigate(`/batched-sales/order/edit/${order.order_id}`);
			else
				Grape.navigate(`/batched-sales/order/view/${order.order_id}`);
		else
			if (this.can_capture_order(order))
				Grape.navigate(`/stock/order/edit/${order.order_id}`);
			else
				Grape.navigate(`/stock/order/view/${order.order_id}`);
	}

	page_click (page_number)
	{
		this.current_page_number(page_number);
		this.updateData();
	}

	btn_search_click ()
	{
		this.updateData();
	}

	sortClick (field)
	{
		this.sortField(field);

		if (this.sortOrder() == 'ASC')
			this.sortOrder('DESC');
		else
			this.sortOrder('ASC');

		this.updateData();
	}

	save_filter_click ()
	{
		if (this.current_filter_id())
			this.upsert_user_filter('PATCH', this.current_filter_id());
		else 
			this.upsert_user_filter('POST');

		this.loadfilters();
	}

	delete_filter_click ()
	{
		this.upsert_user_filter('DELETE', this.current_filter_id());

		this.clear_filters();

		this.loadfilters();
		this.updateData();
	}

	user_filter_selected (data)
	{
		this.current_filter_name(data.filter_name);
		this.current_filter_public(data.public);
		this.current_filter_id(data.user_filter_id);
		this.current_filter_criteria(data.filter_criteria);

		localStorage.setItem(`${this.type().replace(" ", "")}Filter`, this.current_filter_name());
		this.loadfilters();
	}

	clear_filter_click ()
	{
		this.clear_filters()

		this.updateData();
		Grape.alerts.alert({ type: 'success', title: 'Success', message: 'filter cleared' });
	}

	async clear_filters ()
	{
		this.current_filter_name('');
		this.current_filter_public('');
		this.current_filter_id(null);
		this.current_filter_criteria([]);

		this.selected_from_date('');
		this.selected_to_date('');
		this.selected_statuses([]);
		this.selected_sources([]);
		this.selected_destinations([]);
		this.display_completed_selected('No');
		this.display_cancelled_selected('No');
		localStorage.setItem(`${this.type().replace(" ", "")}Filter`, '');

		localStorage.removeItem(this.ls_prefix + '_selected_sources');
		localStorage.removeItem(this.ls_prefix + '_selected_destinations');
		localStorage.removeItem(this.ls_prefix + '_selected_statuses');
		localStorage.removeItem(this.ls_prefix + '_selected_from_date');
		localStorage.removeItem(this.ls_prefix + '_selected_to_date');
		localStorage.removeItem(this.ls_prefix + '_display_completed_selected');
		localStorage.removeItem(this.ls_prefix + '_display_cancelled_selected');
		localStorage.removeItem(this.ls_prefix + '_search_string');
	}

	async init ()
	{
		this.loading(true);
		document.title = this.type();

		let [locations, statuses] = await Promise.all([
			Grape.cache.fetch('Locations'),
			Grape.cache.fetch('OrderStatuses'),
			Promise.resolve({records: []})
		]);

		let supplier_names = [];
		locations.forEach((loc) => {
			if (loc.location_type == 'Supplier')
				supplier_names.push(loc);
		});
		this.available_suppliers(supplier_names);

		let location_names = [];
		locations.forEach((loc) => {
			if (loc.location_type != 'Supplier')
				location_names.push(loc);
		});
		this.available_locations(location_names);
		this.creatable_locations = await window.Grape.StockUtils.get_user_locations('CreateOrder');

		let available_statuses = [];
		statuses.forEach((status) => {
			if (status.type == this.type())
				available_statuses.push(status.status);
		});
		this.available_statuses(available_statuses);

		let retrievedFilter = localStorage.getItem(`${this.ls_prefix}Filter`);
		this.current_filter_name(retrievedFilter);

		this.loadfilters();
		this.loading(false);

		this.initialized(true)
	}

	async updateData ()
	{
		this.loading(true);
		let options = {
			table: 'v_orders',
			schema: 'stock',
			offset: 0,
			sortorder: this.sortOrder(),
			sortfield: this.sortField(),
			filter_join: 'AND',
			filter: []
		}

		let filter_base = [
			{
				field: 'order_type',
				operand: '=',
				value: this.type()
			}
		];

		// LOGIC: filter 
		if (this.selected_statuses().length > 0)
			filter_base.push({
				field: 'status',
				operand: 'IN',
				value: this.selected_statuses()
			});

		if (this.selected_sources().length > 0)
			filter_base.push({
				field: 'source_location',
				operand: 'IN',
				value: this.selected_sources().map(src => src.name)
			});

		if (this.selected_destinations().length > 0)
			filter_base.push({
				field: 'target_location',
				operand: 'IN',
				value: this.selected_destinations().map(dest => dest.name)
			});

		if (this.selected_from_date())
			filter_base.push({
				field: 'order_date',
				operand: '>=',
				value: this.selected_from_date()
			});

		if (this.selected_to_date())
			filter_base.push({
				field: 'order_date',
				operand: '<=',
				value: this.selected_to_date()
			});

		if (this.display_completed_selected() == 'No')
			filter_base.push({
				field: 'completed', 
				operand: '=', 
				value: 'false'
			});

		if (this.display_cancelled_selected() == 'No')
			filter_base.push({
				field: 'cancelled', 
				operand: '=', 
				value: 'false'
			});

		// LOGIC: Search
		if (this.search_string() && this.search_string() != '')
		{
			options.filter_join = "OR";
			options.filter = [
				{
					join: 'AND',
					filter: [
						{ 
							field: 'order_nr', 
							operand: 'ILIKE', 
							value: `%${this.search_string()}%` 
						}
					].concat(filter_base)
				},
				{
					join: 'AND',
					filter: [
						{ 
							field: 'reference_numbers', 
							operand: 'ILIKE', 
							value: `%${this.search_string()}%` 
						}
					].concat(filter_base)
				}
			]
		}
		else
			options.filter = filter_base;

		this.currentOptions(options);

		// LOGIC: Pagination
		if (this.current_page_number() && this.current_page_size())
		{
			options.limit = this.current_page_size();
			options.offset = (this.current_page_number()-1) * this.current_page_size();
		}

		try
		{
			let result = await Grape.fetches.getJSON('/api/record', options);

			if (result.status != 'ERROR')
			{
				result.records.forEach(order => {
					let order_nr_txt = order.order_nr;
					if (order.parent_order_id)
					{
						order_nr_txt += ` (${order.parent_order_nr})`;
					}

					order.order_nr_txt = order_nr_txt;
				});

				this.orders(result.records);
				this.page_count(Math.ceil(result.total/result.limit));
				this.pagination_summary(`Showing ${options.offset} - ${this.current_page_number() * this.current_page_size()} of ${result.total} order(s)`);
			}
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error)
		}
		this.loading(false);
	}

	can_capture_order (order) 
	{
		console.log("🚀 ~ order.target_location:", order.target_location)
		console.log("🚀 ~ order.source_location:", order.source_location)
		let allow = false;

		let has_loc_permission = false;

		if (order.source_location && order.target_location)
			has_loc_permission = (this.creatable_locations.find(loc => loc.location_name == order.source_location)) && (this.creatable_locations.find(loc => loc.location_name == order.target_location));
		else if (order.source_location && !order.target_location)
			has_loc_permission = (this.creatable_locations.find(loc => loc.location_name == order.source_location));
		else if (order.target_location && !order.source_location)
			has_loc_permission = (this.creatable_locations.find(loc => loc.location_name == order.target_location));

		if (Grape.currentSession.roles.includes('stock.all-location-permissions') || (has_loc_permission))
			allow = true;


		return allow;
	}

	async upsert_user_filter (method, user_filter_id)
	{
		if (this.current_filter_public() == '')
			this.current_filter_public(false);

		let filter_criteria = {
			selected_from_date: this.selected_from_date(),
			selected_to_date: this.selected_to_date(),
			selected_statuses: this.selected_statuses(),
			selected_sources: this.selected_sources(),
			selected_destinations: this.selected_destinations(),
			display_completed_selected: this.display_completed_selected(),
			display_cancelled_selected: this.display_cancelled_selected()
		};

		let bodyObj = {
			table: 'user_filter',
			schema: 'stock',
			returning: '*'
		};

		if (method != 'DELETE')
			bodyObj.values = {
				user_uuid: Grape.currentSession.user.uuid,
				public: this.current_filter_public(),
				filter_type: `${this.type().replace(" ", "")}`,
				filter_name:  this.current_filter_name(),
				filter_criteria: filter_criteria
			}

		if ((method === 'PATCH' || method === 'DELETE') && user_filter_id != null)
			bodyObj.filter = [{ 
				field: 'user_filter_id',
				operand: '=',
				value: user_filter_id
			}];
		
		try
		{
			if (!(method === 'POST' || method === 'PATCH' || method === 'DELETE'))
				throw new Error('Incorrect method passed');

			let response = await fetch('/api/record/', {
				method: method,
				body: JSON.stringify(bodyObj),
				headers: { 'content-type': 'application/json' }
			});

			let data = await response.json();

			if (response.ok)
			{
				this.current_filter_criteria(data.filter_criteria);
				if (method === 'POST')
					Grape.alerts.alert({ title: 'Success', type: 'success', message: 'Filter saved' });
				else if (method === 'DELETE')
					Grape.alerts.alert({ title: 'Success', type: 'success', message: 'Filter deleted' });
				else 
					Grape.alerts.alert({ title: 'Success', type: 'success', message: 'Filter updated' });
			}
			else
				throw new Error(data.message);
		} catch (err) {
			console.error(err);
			Grape.alerts.alert({ title: 'Error', type: 'error', message: err.message });
		} finally {
			localStorage.setItem(`${this.type().replace(" ", "")}Filter`, this.current_filter_name());
		}
	}

	async loadfilters ()
	{
		try
		{
			let result = await Grape.fetches.getJSON('/api/record', {
				table: 'v_user_filter',
				schema: 'stock',
				filter: [{
					field: 'filter_type',
					operand: '=',
					value: `${this.type().replace(" ", "")}`
				}]
			}); 

			if (result.status == 'OK')
			{
				if (result.records.length > 0)
				{
					let current_filter = result.records.find(res => this.current_filter_name() == res.filter_name)

					if (current_filter)
					{
						this.current_filter_criteria(current_filter.filter_criteria);
						this.current_filter_public(current_filter.public);
						this.current_filter_id(current_filter.user_filter_id);

						const filter_criteria = current_filter.filter_criteria;
						this.selected_from_date(filter_criteria.selected_from_date);
						this.selected_to_date(filter_criteria.selected_to_date);
						this.selected_statuses(filter_criteria.selected_statuses);
						this.selected_sources(filter_criteria.selected_sources);
						this.selected_destinations(filter_criteria.selected_destinations);
						this.display_completed_selected(filter_criteria.display_completed_selected || 'No');
						this.display_cancelled_selected(filter_criteria.display_cancelled_selected || 'No');
					}

					this.filters_array(result.records);
				}
				else 
					this.current_filter_name('');
				this.updateData();
			}
			else
				throw new Error(result.message);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		} 
	}
}

export default {
	name: 'order-list',
	viewModel: OrderListViewModel,
	module_type: 'ko',
	template: template
}