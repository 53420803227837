import template from './inter_branch_transfers.html';

class IBTViewModel
{
	constructor(page)
	{
		this.page = page;
	}
}

class IBTPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new IBTViewModel(this);
	}
}

export default {
	route: '/stock/order/ibt/',
	page_class: IBTPage,
	template: template
}