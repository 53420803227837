import template from './si_pricing_page.html';

class StockItemPricingPageVM
{
	constructor (page)
	{
		this.page = page;
		this.loaded = ko.observable(false);
		this.stock_item_id = ko.observable();
		this.price_at_location = ko.observableArray([]);
		this.locations = ko.observableArray([]);
		this.selected_locations = ko.observableArray([]);
		this.new_price = ko.observable();
		this.new_date_effective = ko.observable(new Date().toISOString().slice(0, 10));
		this.new_min_order_qty = ko.observable(0);
		this.show_pricing_update = ko.observable(false);
		this.select_all_locations = ko.observable(false);

		this.selected_locations.subscribe(() => this.page.updateData() );

		this.select_all_locations.subscribe(is_checked => {
			this.price_at_location().forEach(item => item.is_selected(is_checked));
		});
	}

	btn_edit_row_click (row)
	{
		row.state('editing');
		row.latest_price_info().forEach(info => info.is_editing = ko.observable(false) );

		let price, min_order_qty;

		if (row.latest_price_info().length > 0 && row.latest_price_info()[0])
		{
			price = row.latest_price_info()[0].price();
			min_order_qty = row.latest_price_info()[0].min_order_qty();
		} 
		else
		{
			price = 0;
			min_order_qty = 0;
		}

		let new_pricing = {
			price: ko.observable(price),
			date_effective: ko.observable(moment().format('YYYY-MM-DD')),
			min_order_qty: ko.observable(min_order_qty),
			is_editing: ko.observable(true)
		};

		row.latest_price_info.unshift(new_pricing);
	}


	btn_cancel_click (row)
	{
		row.latest_price_info.shift();
		row.state('default');
	}
	
	async btn_save_price_click (row) 
	{
		let new_row = row.latest_price_info().find(info => info.is_editing());
	
		if (new_row) 
		{
			if (!new_row.price() || !new_row.date_effective()) 
			{
				Grape.alerts.alert({
					title: 'Error',
					type: 'error',
					message: 'All fields must be filled in before saving.'
				});
				return;
			}
	
			let data = {
				idx: row.idx,
				code: row.code,
				stock_item_id: this.stock_item_id(),
				location_id: row.location_id,
				prices: {
					add: [{
						price: new_row.price(),
						min_order_qty: new_row.min_order_qty(),
						date_effective: new_row.date_effective()
					}]
				}
			};
	
			try
			{
				let result = await Grape.fetches.postJSON('/api/stock-management/stock-item-location/location', data);
	
				if (result.status != 'OK')
					throw new Error(result.message || result.code);
			} catch (error) {
				Grape.alerts.alert({ title: 'Error', type: 'error', message: error.message });
				console.error(error);
			} finally {
				new_row.is_editing(false);
			}
		}
		row.state('default');
	}

	async btn_update_prices_click () 
	{
		let selected_locations = this.price_at_location().filter(item => item.is_selected());

		if (selected_locations.length === 0)
		{
			Grape.alerts.alert({ type: 'error', title: 'Error', message: 'No locations selected' });
			return;
		}

		try
		{
			if (isNaN(this.stock_item_id()) || isNaN(this.new_price()) || !this.new_date_effective() || isNaN(this.new_min_order_qty()))
				throw new Error('Invalid input');

			for (let location of selected_locations)
			{
				if (location.latest_price_info().length === 0)
				{
					this.btn_edit_row_click(location);
					let new_pricing = location.latest_price_info()[0];
					new_pricing.price(this.new_price());
					new_pricing.date_effective(this.new_date_effective());
					new_pricing.min_order_qty(this.new_min_order_qty());
				}
				else
				{
					let existing_pricing = location.latest_price_info()[0];
					existing_pricing.price(this.new_price());
					existing_pricing.date_effective(this.new_date_effective());
					existing_pricing.min_order_qty(this.new_min_order_qty());
					existing_pricing.is_editing(true);
				}

				await this.btn_save_price_click(location);
			}

			Grape.alerts.alert({ type: 'success', title: 'Success', message: 'Active stock item prices updated!' });
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		} finally {
			this.show_pricing_update(false);
			await this.page.updateData();
		}
	}

	btn_toggle_pricing_visibility ()
	{
		this.show_pricing_update(!this.show_pricing_update());
	}
}

class StockItemPricingPage
{
	constructor (bindings)
	{
		this.viewModel = new StockItemPricingPageVM(this);
		this.bindings = bindings;
		this.viewModel.stock_item_id(bindings.stock_item_id);
		this.timer = null;
	}

	async init ()
	{
		document.title = 'Item Pricing';
		let locations = await Grape.cache.fetch('ActiveLocations');
		this.viewModel.locations(locations);
		this.viewModel.selected_locations(locations);
	}

	async updateData ()
	{
		let stock_location_result = await Grape.fetches.getJSON('/api/record', 
		{
			table: 'v_location_stock_item_info', 
			schema: 'stock',
			fields: [
				'location', 
				'latest_price_info', 
				'location_id', 
				'linked_location_id', 
				'current_price', 
				'code', 
				'idx', 
				'location_in_use', 
				'stock_item_in_use',
				'uom_name',
				'uom_symbol',
				'uom_qty'
			],
			filter: [
				{
					field: 'stock_item_id', 
					value: this.viewModel.stock_item_id(), 
					operand: '='
				}, 
				{
					field: 'location_id',
					value: this.viewModel.selected_locations().map(loc => loc.location_id),
					operand: 'IN'
				},
				{
					field: 'location_in_use',
					value: true,
					operand: '='
				}
			]
		});

		if (stock_location_result.status == 'OK') 
		{
			let si_loc_price = [];
			this.viewModel.locations().forEach((loc, idx) => {
				let price_info = stock_location_result.records.find(rec => rec.location_id == loc.location_id) || {latest_price_info: []};

				si_loc_price.push({
					code: price_info.code || loc.code || '',
					current_price: price_info.current_price || 0.00,
					latest_price_info: ko.observableArray( price_info.latest_price_info ?
						price_info.latest_price_info.map(info => {
							return {
								price: ko.observable(info.price),
								date_effective: ko.observable(info.date_effective),
								min_order_qty: ko.observable(info.min_order_qty),
								is_editing: ko.observable(false)
							};
						}) : []
					),
					location: price_info.location || loc.name,
					location_id: price_info.location_id || loc.location_id,
					linked_location_id: price_info.linked_location_id || loc.location_id,
					state: ko.observable('default'),
					is_selected: ko.observable(price_info.latest_price_info.length > 0),
					uom_name: price_info.uom_name,
					uom_qty: price_info.uom_qty
				});

				si_loc_price[idx].latest_price_info.sort((a, b) =>
					moment(b.date_effective(), 'YYYY-MM-DD').diff(moment(a.date_effective(), 'YYYY-MM-DD'))
				);
			});

			this.viewModel.price_at_location(si_loc_price);
		}
	}

	teardown ()
	{
		clearTimeout(this.timer);
	}
}

export default {
	route: '[/stock_item/]si_pricing',
	page_id: 'si_pricing',
	page_class: StockItemPricingPage,
	template: template
};
