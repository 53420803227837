// call with window.Grape.StockUtils.---the function---

class StockUtils
{
	/**
	 * Group a list of location records according to hierarchy
	 */
	static format_locations_list(list)
	{
		let locations_formatted = [];
		for (let record of list)
		{
			record.parent_names = record.parent_names || [];
			record.children = [];
			let curr = locations_formatted; // current children list
			for (let i = record.parent_names.length-1; i >= 0; i--)
			{
				const pn = record.parent_names[i];
				let next = curr.find((x) => x.name == pn);
				if (!next)
				{
					curr.push({name: pn, children: []});
					next = curr.find((x) => x.name == pn);
				}
				curr = next.children;
			}
			let n = curr.find((x) => x.name == record.name);
			if (!n)
				curr.push(record);
			else
			{
				for (let [k,v] of Object.entries(record))
				{
					if (k == 'name' || k == 'children')
						continue;
					n[k] = v;
				}
			}
		}
		return locations_formatted;
	}

	/**
	 * Flatten a list of locations
	 */
	static flatten_locations (locations, depth = 0)
	{
		let flat_list = [];
		for (let location of locations)
		{
			flat_list.push({...location, depth});
			flat_list = flat_list.concat(this.flatten_locations(location.children, depth + 1));
		}
		return flat_list;
	}

	/**
	 * Get user locations
	 */
	static async get_user_locations (permission)
	{
		let locations = [];
		let data = await Grape.cache.fetch('UserLocations');
		for (let i = 0; i < data.locations.length; i++)
		{
			let loc = data.locations[i];
			if (loc.permissions.indexOf(permission) != -1)
				locations.push({ location_id: loc.location_id, location_name: loc.location_name });
		}
	
		return locations;
	}

	/**
	 * Get formatted currency
	 */
	static get_format_currency(value, symbol = window.Grape.config.currency_symbol)
	{
		return `${symbol}${value.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}`;
	}
	
	/**
	 * Get formatted thousands
	 */
	static get_format_thousands(value)
	{
		let rx = /(\d+)(\d{3})/;
		let is_negative = String(value).startsWith('-');

		if (is_negative)
			value = String(value).slice(1);

		let formatted_value = String(value).replace(/^\d+/, w => {
			while (rx.test(w))
				w = w.replace(rx, '$1 $2');
			return w;
		});

		return is_negative ? `-${formatted_value}` : formatted_value;
	}

	static fileupload(params)
	{
		var request_params = {
			form_id: null, 
			header_info: {},
			api : "", 
			success_message : "", 
			_onclose : function() {}
		};

		Object.assign(request_params, params);

		//DEFAULT Header info
		request_params.header_info["X-SessionID"] = localStorage.getItem('session_id');
		request_params.header_info["Accept"] = 'application/json';

		//SERVER: Upload file(s)
		fetch(request_params.api, {
			method: 'post',
			body: new FormData(request_params.form_id),
			headers: request_params.header_info
		}).then((response)=>{
			if (response.ok) {
				Grape.alerts.alert({type: 'success', message: (!request_params.success_message)? "Files have been uploaded.": request_params.success_message, title: ' File Upload', onClose: function() {
						if(typeof(request_params._onclose) == "function")
							request_params._onclose();	
				}});
			}
			else 
				Grape.alerts.alert({type:'error', message: 'File upload failed: ' + response.statusText + '. Please notify support. ', title:'File Upload' }); 
		}).catch((exception) => {
			Grape.alerts.alert({type:'warning', message: exception, title:'File Upload'});
		});
	}

	/**
	 * Upload a file
	 * @param {Array[{}]} stock_items -
	 * @param {Number} stock_items.stock_item_id - stock item id
	 * @param {Number} stock_items.qty - stock item count
	 * @param {Text} stock_items.stock_item- stock item description
	 * @param {Number|Text} location - can either pass location_id (string|number) or location_name (string)
	 * @param {Date} date_effective - 
	 **/
	static async verify_loc_stock_levels(location, date_effective, stock_items)
	{
		if (!date_effective)
			date_effective = moment().format('YYYY-MM-DD');

		let stock_item_ids = stock_items.map((stock_item)=> stock_item.stock_item_id);

		try
		{	
			let options = {date_effective: date_effective, stock_item_ids: stock_item_ids};

			if (location && !isNaN(location))
				options.location_ids = [location];
			else if (location && isNaN(location))
				options.location = location;
			else 
				throw new Error('Invalid location passed');
				
			let levels = await Grape.fetches.getJSON('/api/stock-management/stock-level', options);

			if (levels.status != 'ERROR')
			{
				let verify = true;
				levels.records.forEach(rec => {
					let item = stock_items.find(item => item.stock_item_id == rec.stock_item_id);
					if (item && ((Number(rec.qty) + Number(item.qty)) < 0))
						verify = false;
				});

				return verify;
			}
			else
				throw new Error(levels.message || levels.code);
		} catch (error) {
			Grape.alerts.alert({type: 'error', title: 'Error', message: error.message});
			console.error(error)
		}
	}
	
	static async upload_fs_file(files, path)
	{
		let success = true;
		try
		{
			if (files.length > 0)
			{
				for (let i = 0; i < files.length; i++)
				{
					let file = files[i];
					let full_path = `${path}/${file.name}`;

					let response = await fetch(full_path, {
						method: 'PUT',
						headers: { 'content-type': file.type },
						body: file
					});

					if (!response.ok)
						throw new Error(`"File upload failed for", ${file.name}`);
				}
			}
			else
				throw new Error('No files selected!');
		} catch (error) {
			success = false;
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}

		return success;
	}

	static async delete_fs_file(file)
	{
		let success = true;
		try 
		{
			if (file.full_path == "")
				throw new Error('No path specified!');

			let response = await fetch(file.full_path, {
				method: 'DELETE',
				headers: { 'content-type': 'application/json' },
				body: []
			});

			if (!response.ok)
				throw new Error('File deletion failed', response.status);
		} catch (error) {
			success = false;
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}

		return success;
	}

	static async load_fs_folder(path)
	{
		let files = [];

		try
		{
			// TODO: Test if the directory exists before performing a PROPFIND
			let file_result = await Grape.fetches.fetchJSON(path,
			{
				headers: {'content-type': 'application/json'}, 
				method: 'PROPFIND', 
				body: JSON.stringify([])
			});
			file_result.shift();

			for (let file of file_result)
			{
				let file_info = {
					full_path: file.full_path,
					file_name: '',
					user: '',
					date: ''
				};

				for (let prop of file.properties)
					if (prop.name === 'displayname')
						file_info.file_name = prop.value;
					else if (prop.name === 'creator-displayname')
						file_info.user = prop.value;
					else if (prop.name === 'creationdate')
						file_info.date = prop.value;

				if (file_info.file_name === "")
				{
					const fileName = file.file_path.split('/').pop();
					file_info.file_name = fileName;
				}

				files.push(file_info);
			}
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}

		return files;
	}
}

export default StockUtils;
