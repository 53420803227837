import template from './order_statuses.html';

class OrderStatusesViewModel
{
	constructor (dialog)
	{
		this.dialog = dialog;

		this.order_statuses = ko.observableArray([]);
		this.new_statuses = ko.observableArray([]);
	}

	btn_back_click ()
	{
		this.dialog.close();
	}

	async btn_confirm_click ()
	{
		const order_statuses = ko.toJS(this.order_statuses);
		
		// Validation

		if (order_statuses.find((x) => x.selected_status === undefined))
		{
			await Grape.alerts.alert({ type: 'warning', title: 'New Status', message: 'Please specify a new status for each current status' });
			return;
		}

		this.dialog.close(order_statuses);
	}
}

class OrderStatusesDialog
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new OrderStatusesViewModel(this);

		const new_statuses = this.bindings.new_statuses.map((x) => x.name);
		new_statuses.push(this.bindings.new_statuses[0].reject_status);
		this.viewModel.new_statuses(new_statuses);

		const order_statuses = this.bindings.old_statuses.map((x) => { x.selected_status = ko.observable(); return x; });
		this.viewModel.order_statuses(order_statuses);
	}
}

export default {
	name: 'OrderStatuses',
	dialog_class: OrderStatusesDialog,
	template: template
}
