export default {
	name: 'ActiveCarriedStockItems',
	options: {
		tablename: 'v_stock_item',
		schema: 'stock',
		limit: 10000,
		fields: ['description', 'stock_item_id', 'code', 'in_use'],
        filter: [
            {
                field: 'levels',
                operand: '>',
                value: 0
            },
            {
                field: 'in_use',
                operand: '=',
                value: true
            }
        ],
		filter_join: 'AND'
	}
};
