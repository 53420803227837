
export default {
	name: 'AllLocations',
	options: {
		table: 'v_all_locations',
		limit: 10000,
		schema: 'stock',
		sortfield: 'location_id'
	}
};
