import template from './l_users_page.html';
import LocationsViewAPI from './../location_view_api.js';

class LocationUsersViewModel
{
	constructor (page)
	{
		this.loaded = ko.observable(false);
		this.page = page;
		this.location_id = ko.observable(page.bindings.location_id);
		this.permissions = ko.observableArray([ 'ViewLocation', 'EditLocation', 'CreateOrder', 'ConfirmOrder', 'CreateStockTake', 'ConfirmStockTake', 'ViewStockLevel', 'CreateStockMovement', 'ConfirmStockMovement' ]);

		//add user permission
		this.add_perm_visible = ko.observable(false);
		this.add_location_permission = ko.observable({ username: null, location_permission_names: ko.observableArray([]), modified: true });
		this.available_users = ko.observableArray([]);
		this.selected_user = ko.observable();

		this.location_permissions = ko.observableArray([]);
		this.can_edit = ko.observable(false);
	}

	all_permission_checked(row)
	{
		let index = this.location_permissions().indexOf(row);
		let location_permissions = this.location_permissions();
		location_permissions[index].location_permission_names(this.permissions());
		this.location_permissions(location_permissions);
	}

	all_permission_checked_new_user()
	{
		this.add_location_permission().location_permission_names(this.permissions());
	}

	async save_permissions_click ()
	{
		let success = true;
		for (let perm of this.location_permissions())
			if (perm.modified && success)
				success = await this.page.save_permissions(perm);

		if (success)
			Grape.alerts.alert({ type: 'success', title: 'Success', message: 'User permission(s) added/updated!' });

		this.page.updateData();
	}

	async add_permission_click ()
	{
		this.add_perm_visible(!this.add_perm_visible());
	}

	async add_new_permissions_click ()
	{
		if (this.selected_user())
			this.location_permissions.push({ 
				location_id: this.location_id(), 
				location_permission_names: ko.observableArray(this.add_location_permission().location_permission_names()), 
				username: this.selected_user().username,
				modified: true 
			});

		this.clear_permissions();
	}

	clear_permissions ()
	{
		this.add_location_permission({ username: null, location_permission_names: ko.observableArray([]), modified: true });
		this.selected_user(null);
		this.add_perm_visible(false);
	}
}

class LocationUsersPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new LocationUsersViewModel(this);
		this.LocationsViewAPI = new LocationsViewAPI(this.viewModel.location_id());
		this.viewModel.add_location_permission({ username: null, location_permission_names: ko.observableArray([]) });
	}

	async init ()
	{
		document.title = 'Location Users';

		let editable_locations = await window.Grape.StockUtils.get_user_locations('EditLocation');
		let editable = false;
		if (Grape.currentSession.roles.includes('stock.all-location-permissions') || editable_locations.find(loc => loc.location_id == this.viewModel.location_id()))
			editable = true;

		this.viewModel.can_edit(editable);
	}

	async updateData ()
	{
		let result = await this.get_users();
		result.records.forEach(record => {
			if (!record.location_permission_names)
				record.location_permission_names = [];
			record.modified = false;
			record.location_permission_names = ko.observableArray(record.location_permission_names);
			record.location_permission_names.subscribe(() => record.modified = true ); // update row if modified
		});

		this.viewModel.location_permissions(result.records);

		let users = await Grape.cache.fetch('StockUsers');
		let available_users = [];
		for (let user of users)
			if (available_users.indexOf(user) == -1 && !this.viewModel.location_permissions().find(usr => usr.username == user.username))
				available_users.push(user);

		this.viewModel.available_users(available_users);
	}

	async save_permissions (perm)
	{
		let success = true;
		try
		{
			let values = this.build_values(perm);
			let result = await fetch('/api/stock-management/user-location', { 
				method: 'POST',
				body: JSON.stringify(values),
				headers: { 'content-type': 'application/json' }
			});

			let data = await result.json();

			if (data.status != 'OK')
				throw new Error(data.message || data.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error)

			success = false;
		} finally {
			return success
		}
	}

	build_values (perm)
	{
		let obj = {
			username: perm.username,
			locations: [{
				permissions: perm.location_permission_names(),
				location_id: perm.location_id
			}]
		}

		return obj;
	}

	async get_users ()
	{
		try
		{
			let result = await Grape.fetches.getJSON('/api/record', {
				table: 'v_location_users',
				schema: 'stock',
				filter: [
					{
						field: 'location_id',
						operand: '=',
						value: this.viewModel.location_id()
					},
					{
						field: 'active',
						operand: '=',
						value: true
					}
				]
			});

			if (result.status != 'ERROR')
				return result;
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}
}

export default {
	route: '[/location/]l_users',
	page_id: 'l_users',
	page_class: LocationUsersPage,
	template: template
};