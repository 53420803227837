import template from './si_production_page.html';

class ProductionPageVM
{
	constructor (page)
	{
		this.page = page;
		this.editor = null;
		this.bom_id = ko.observable(null);
		this.stock_item_id = ko.observable();
		this.loaded = ko.observable(false);
		this.edit_mode = ko.observable(false);
		this.delete_steps_visible = ko.observable(false);
		this.materials_view = ko.observable(false);
		this.workstation_options = ko.observableArray([]);
		this.components = ko.observableArray([]);
		this.versions = ko.observableArray([]);
		this.selected_version = ko.observable();
		this.selected_item = ko.observableArray([]);

		this.workstation_flow_steps = ko.observableArray([]);
		this.removed_steps = ko.observableArray([]);
		this.instructions_files = ko.observableArray([]);
		this.new_version = ko.observable({ version: 0, version_name: '', version_date: moment(new Date()) });

		this.work_orders = ko.observableArray([]);
		this.highest_work_order_nr = ko.observable(1);
		this.new_work_order = ko.observable('');

		this.components_visible = ko.observable(true);
		this.workstation_flow_visible = ko.observable(false);
		this.instructions_visible = ko.observable(false);

		this.lead_time = ko.observable({
			days: ko.observable(0),
			hours: ko.observable(0),
			minutes: ko.observable(0)
		});
		
		this.edit_mode.subscribe(edit_mode => {
			if (!edit_mode)
				this.editor.edit.off();
			else
				this.editor.edit.on();
		});	

		this.materials_view.subscribe(async (new_value) => {
			if (new_value)
			{
				this.collapse_all_boms();
				await this.switch_to_materials_view();
			}
			else
			{
				await this.switch_to_components_view();
				this.get_sub_bom_prices()
			}
		});

		this.total_cost = ko.computed(() => {
			let total = 0;
			this.components().forEach(component => {
				if (component.is_bom)
					if (component.expanded())
						return;
		
				let qty = parseFloat(ko.unwrap(component.qty)) || 0;
				let price = parseFloat(ko.unwrap(component.supplier_price)) || 0;
				total += qty * price;
			});
			return total;
		});

		this.init();
	}

	async init ()
	{
		await new Promise((resolve) => {
			this.editor = new FroalaEditor('#froala-editor', {
				key: Grape.froala.key,
				attribution: false, // remove "Powered by" watermark
				toolbarButtons: Grape.froala.toolbarButtons,
				tableResizerOffset: 10,
				tableResizingLimit: 50
			}, resolve);
		});

		if (Grape.currentSession.roles.includes('stock.super_user'))
			this.delete_steps_visible(true);
	}

	async get_sub_bom_prices ()
	{
		let bom_items = this.components().filter(component => component.is_bom && !component.expanded());

		// Open for calc
		for (let bom_item of bom_items)
			await this.expand_bom_item_click(bom_item);

		// Close after calc
		for (let bom_item of bom_items)
			await this.expand_bom_item_click(bom_item);
	}

	async select_version_click (version)
	{
		await this.page.select_version(version);
		await this.page.update_data();
	}

	async add_version_click()
	{
		await this.page.save_bom_stock_item('INSERT');
	}

	async add_workstation_click()
	{
		Grape.navigate('/workstation/setup');
	}

	update_version_click ()
	{
		this.components().forEach(component => {
			let steps = this.workstation_flow_steps().map(step => {
				return {
					step_nr: step.step_nr,
					bom_step_id: step.bom_step_id,
					name: step.name
				};
			});
			component.available_steps = ko.observableArray(steps || []);
		});

		this.edit_mode(true);		
	}

	save_version_click ()
	{
		this.page.save_bom_stock_item('UPDATE');
	}

	cancel_version_click ()
	{
		this.edit_mode(false);
	}

	async add_component_click ()
	{
		let selected_item = this.selected_item();
		let available_steps = this.workstation_flow_steps().map(step => ({ 
			step_nr: ko.unwrap(step.step_nr),
			name: ko.unwrap(step.name),
			bom_step_id: ko.unwrap(step.bom_step_id)
		}));

		let new_component = {
			stock_item_id: selected_item.stock_item_id,
			code: selected_item.code,
			stock_item: selected_item.description,
			stock_group: selected_item.stock_group,
			uom_name: selected_item.uom_name,
			uom_qty: selected_item.uom_qty,
			qty: ko.observable(0),
			selected_usage_method: ko.observable('Auto'),
			selected_usage_step: ko.observable(null),
			is_bom: selected_item.is_bom || false,
			is_secondary_bom_component: false,
			is_combined_item: false,
			expanded: ko.observable(false),
			supplier_price: ko.observable(0),
			cost_percentage: ko.observable(0),
			available_steps: ko.observable(available_steps),
			sub_procedure: ko.observable(false)
		};

		this.components.push(new_component);
		this.selected_item('');
		this.edit_mode(true);
	}

	async remove_component_click (component)
	{
		let res = await Grape.alerts.confirm({ type: 'warning', title: 'Remove Component?', message: 'Are you sure you want to remove this component?' });
		if (!res) return;

		this.components.splice(this.components().indexOf(component), 1);
	}

	add_workstation_flow_step_click ()
	{
		let next_step = this.workstation_flow_steps().length + 1;
		let available_steps = this.workstation_flow_steps().filter(step => ko.unwrap(step.step_nr) < next_step).map(step => ({ 
			step_nr: ko.unwrap(step.step_nr),
			name: ko.unwrap(step.name) 
		}));

		let new_step = {
			bom_step_id: null,
			step_nr: ko.observable(next_step),
			name: ko.observable(''),
			location_id: ko.observable(''),
			available_steps: ko.observableArray(available_steps || 0),
			predependencies: ko.observableArray([]),
			work_order_nr: ko.observable(''),
			selected_work_order: ko.observable({ label: '-', value: null }),
			duration_per_item: ko.observable({
				days: ko.observable(0),
				hours: ko.observable(0),
				minutes: ko.observable(0)
			}),
			instructions: ko.observable(''),
			instructions_files: ko.observableArray([])
		};

		new_step.selected_work_order.subscribe(newValue => new_step.work_order_nr(newValue ? newValue.value : null));
		this.workstation_flow_steps.push(new_step);
	}

	async delete_workstation_flow_step_click (step, skip_confirm = false)
	{
		let deleted_step_nr = ko.unwrap(step.step_nr);

		if (!skip_confirm)
		{
			let response = await Grape.alerts.confirm({ 
				type: 'danger', 
				title: 'Delete Step?', 
				message: 'Are you sure you want to delete this step?' 
			});
			if (!response) return;
		}
	
		if (!step.bom_step_id)
		{
			this.workstation_flow_steps.remove(step);
			return;
		}

		this.removed_steps.push(step);
		this.workstation_flow_steps.remove(step);

		this.workstation_flow_steps().forEach(s => {
			let predeps = ko.unwrap(s.predependencies);
			let updated_predeps = predeps.filter(predep => predep !== deleted_step_nr);
			s.predependencies(updated_predeps);
		});

		this.workstation_flow_steps().forEach((step, index) => {
			step.step_nr(index + 1);
		});
	
		this.workstation_flow_steps().forEach(step => {
			let step_nr = ko.unwrap(step.step_nr);
			let available_steps = this.workstation_flow_steps().filter(s => ko.unwrap(s.step_nr) < step_nr).map(s => ({
				step_nr: ko.unwrap(s.step_nr),
				name: ko.unwrap(s.name)
			}));
			step.available_steps(available_steps);
		});
	}

	async copy_workstation_flow_click ()
	{
		try {
			let flow_steps = ko.toJS(this.workstation_flow_steps);
			let flow_json = JSON.stringify(flow_steps);

			await navigator.clipboard.writeText(flow_json);

			Grape.alerts.alert({
				type: 'success',
				title: 'Copied',
				message: 'Workstation flow steps copied to your clipboard!'
			});
		} catch (err) {
			console.error(err);

			Grape.alerts.alert({
				type: 'error',
				title: 'Error',
				message: 'Failed to copy flow steps to clipboard!'
			});
		}
	}

	async paste_workstation_flow_click ()
	{
		try {
			let confirm = await Grape.alerts.confirm({ type: 'warning', title: 'Confirm', message: 'Are you sure? This will override any existing steps!' });
			if (!confirm)
				return;

			let clipboard_content = await navigator.clipboard.readText();

			if (!clipboard_content)
			{
				Grape.alerts.alert({
					type: 'warning',
					title: 'No Data',
					message: 'Clipboard is empty or invalid!'
				});
				return;
			}

			let parsed_flow = JSON.parse(clipboard_content);

			if (!Array.isArray(parsed_flow))
			{
				Grape.alerts.alert({
					type: 'warning',
					title: 'Invalid Data',
					message: 'Clipboard content is not valid flow data!'
				});
				return;
			}

			let existing_steps = [...this.workstation_flow_steps()];
 
			for (let step of existing_steps)
				await this.delete_workstation_flow_step_click(step, true); 

			parsed_flow.forEach(stepData => {
				let new_step = {
					bom_step_id: null,
					step_nr: ko.observable(stepData.step_nr),
					name: ko.observable(stepData.name),
					location_id: ko.observable(stepData.location_id),
					available_steps: ko.observableArray(stepData.available_steps || []),
					predependencies: ko.observableArray(stepData.predependencies || []),
					work_order_nr: ko.observable(stepData.work_order_nr),
					selected_work_order: ko.observable({ 
						label: stepData.selected_work_order?.label || '-', 
						value: stepData.selected_work_order?.value || null 
					}),
					duration_per_item: ko.observable({
						days: ko.observable(stepData.duration_per_item?.days || 0),
						hours: ko.observable(stepData.duration_per_item?.hours || 0),
						minutes: ko.observable(stepData.duration_per_item?.minutes || 0),
					}),
					instructions: ko.observable(stepData.instructions || ''),
					instructions_files: ko.observableArray(stepData.instructions_files || [])
				};

				new_step.selected_work_order.subscribe(newValue => {
					if (newValue)
						new_step.work_order_nr(newValue.value);
				});

				this.workstation_flow_steps.push(new_step);
			});

			Grape.alerts.alert({
				type: 'success',
				title: 'Pasted',
				message: 'Workstation flow steps pasted successfully!'
			});
		} catch (error) {
			console.error(error);

			Grape.alerts.alert({
				type: 'error',
				title: 'Paste Error',
				message: 'Could not paste flow step data from the clipboard!'
			});
		}
	}

	async expand_bom_item_click (item)
	{
		item.expanded(!item.expanded());
	
		if (!item.expanded())
		{
			this.components.remove(comp => comp.parent_bom_id === item.bom_id);
			return;
		}
	
		try {
			let result = await Grape.fetches.getJSON('api/record', {
				schema: 'stock',
				table: 'v_bom_stock_items',
				filter: [
					{ 
						field: 'bom_id',
						operand: '=', 
						value: item.component_bom_id
					}
				]
			});
	
			if (result.status !== 'OK') throw new Error(result.message || result.code);
	
			let parent_qty = parseFloat(ko.unwrap(item.qty)) || 1;

			let bom_components = result.records.map(component => ({
				...component,
				qty: ko.observable(component.component_qty * parent_qty),
				component_qty: ko.observable(component.component_qty),
				is_secondary_bom_component: true,
				parent_bom_id: item.bom_id,
				expanded: ko.observable(false),
				is_combined_item: false,
				supplier_price: ko.observable(component.supplier_price || 0)
			}));

			let total_supplier_price = bom_components.reduce((sum, comp) => {
				let price = parseFloat(ko.unwrap(comp.supplier_price)) || 0;
				let quantity = parseFloat(ko.unwrap(comp.component_qty)) || 0;
				return sum + (price * quantity);
			}, 0);

			item.supplier_price(total_supplier_price);
	
			let index = this.components.indexOf(item);
			this.components.splice(index + 1, 0, ...bom_components);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}

	collapse_all_boms ()
	{
		let collapse_and_remove = (component) => {
			let child_components = this.components().filter(c => c.parent_bom_id === component.bom_id);
			child_components.forEach(collapse_and_remove);

			if (component.parent_bom_id)
				this.components.remove(component);
			else
				component.expanded(false);
		};
	
		this.components().forEach(item => { if (item.is_bom) collapse_and_remove(item); });
	}

	async switch_to_materials_view ()
	{
		let all_components = [];

		let main_bom = this.components().find(component => component.is_bom);
		let main_bom_qty = main_bom ? parseFloat(ko.unwrap(main_bom.qty)) || 1 : 1;

		let flatten_bom = async (component, multiplier = 1, is_secondary = false) => {
			if (component.is_bom)
			{			
				let result = await Grape.fetches.getJSON('api/record', {
					schema: 'stock',
					table: 'v_bom_stock_items',
					filter: [
						{ 
							field: 'bom_id',
							operand: '=',
							value: component.component_bom_id
						}
					]
				});
	
				if (result.status === 'OK')
				{
					for (let bom_component of result.records)
					{
						await flatten_bom({
							...bom_component,
							qty: ko.observable(parseFloat(bom_component.component_qty * multiplier)),
							is_secondary_bom_component: is_secondary || true,
							parent_bom_id: component.bom_id,
							is_combined_item: false,
							expanded: ko.observable(false)
						}, multiplier, true);
					}
				}
			}
			else
				all_components.push({
					...component,
					is_secondary_bom_component: is_secondary,
				});
		};

		for (let component of this.components())
			if (component.is_bom)
				await flatten_bom(component, main_bom_qty, true);
			else
				all_components.push({
					...component,
					is_secondary_bom_component: component.is_secondary_bom_component,
				});
	
		let combined_components = await this.combine_components(all_components);
		this.components(combined_components);
	}

	async switch_to_components_view ()
	{
		await this.page.get_bom_stock_items();
	}

	combine_components (components)
	{
		let result = [];
		for (let component of components)
		{
			let existing_component = result.find(c => c.component_stock_item_id === component.component_stock_item_id);

			if (existing_component)
			{
				let existing_qty = ko.unwrap(existing_component.qty);
				let new_qty = ko.unwrap(component.qty);
				existing_component.qty(existing_qty + new_qty);
				existing_component.is_combined_item = true;
			}
			else
				result.push({
					...component,
					qty: ko.observable(ko.unwrap(component.qty)),
				});
		}
		return result;
	}

	add_work_order ()
	{
		this.highest_work_order_nr(this.highest_work_order_nr() + 1);

		let new_work_order = {
			label: "Work Order #" + this.highest_work_order_nr(),
			value: this.highest_work_order_nr()
		};

		this.work_orders.push(new_work_order);
		this.new_work_order('');
	}

	async upload_bom_step_click (bom_item) 
	{
		if (!bom_item.bom_step_id)
			return;

		const file_input = document.getElementById('bom_step_attachment_upload');
		let files = file_input.files;

		let response = await window.Grape.StockUtils.upload_fs_file(files, `StockFiles/BOM/Steps/${bom_item.bom_step_id}`)

		if (response)
		{
			Grape.alerts.alert({ type: 'success', title: 'Success', message: 'Instruction(s) successfully uploaded!' });
			this.page.update_data();
		}
	}

	async upload_bom_file_click () 
	{
		if (!this.bom_id())
			return;

		const file_input = document.getElementById('bom_attachment_upload');
		let files = file_input.files;

		let response = await window.Grape.StockUtils.upload_fs_file(files, `StockFiles/BOM/Instructions/${this.bom_id()}`)

		if (response)
		{
			Grape.alerts.alert({ type: 'success', title: 'Success', message: 'Instruction(s) successfully uploaded!' });
			this.page.update_data();
		}
	}

	async btn_delete_instruction_click (file) 
	{
		let response = await Grape.alerts.confirm({
			message: 'Are you sure you want to delete this instruction file?',
			title: 'Delete File',
			type: 'danger'
		})

		if (response)
		{
			response = await window.Grape.StockUtils.delete_fs_file(file);

			if (response)
			{
				Grape.alerts.alert({ type: 'success', title: 'Success', message: 'Document successfully deleted!' });
				this.page.update_data();
			}
		}
	}
}

class ProductionPage
{
	constructor (bindings)
	{
		this.viewModel = new ProductionPageVM(this);
		this.bindings = bindings;
		this.viewModel.stock_item_id(bindings.stock_item_id);
		this.timer = null;
	}

	async init ()
	{
		document.title = 'Production';
		let workstations = await Grape.cache.fetch('ActiveWorkstations');
		this.viewModel.workstation_options(workstations)
		
		await this.update_data();
	}

	async update_data ()
	{
		try
		{
			await this.load_bom()
			await this.load_bom_workstation_flow_steps();
			await this.get_bom_stock_items();
			await this.viewModel.get_sub_bom_prices();
			await this.load_bom_instructions_files();
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}

	async load_bom ()
	{
		try
		{
			let result = await Grape.fetches.getJSON('api/record', {
				schema: 'stock',
				table: 'v_bom',
				filter: [
					{
						field: 'stock_item_id',
						operand: '=',
						value: this.viewModel.stock_item_id()
					}
				]
			});

			if (result.status == 'OK')
			{
				if (this.viewModel.selected_version() == undefined)
					this.viewModel.versions(result.records);

				let version = 1;
				let latest_version = {};
				if (this.viewModel.versions().length > 0)
				{
					version = this.viewModel.versions().reduce((max, current) => { 
						if (current.version > max)
							latest_version = current;

						return current.version > max ? current.version : max 
					}, 0) + 1;

					if (this.viewModel.selected_version() == undefined)
						this.select_version(latest_version);
				}

				this.viewModel.new_version({ version: version, version_name: '', version_date: new Date().toISOString().slice(0, 10) });
			}
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}

	async get_bom_stock_items ()
	{
		try
		{
			if (!this.viewModel.bom_id())
				throw new Error('bom_id is required');
			
			let filter = [{
					field: 'bom_id',
					operand: '=',
					value: this.viewModel.bom_id()
				}]

			let result = await Grape.fetches.getJSON('api/record', {
				schema: 'stock',
				table: 'v_bom_stock_items',
				filter: filter
			});
		
			if (result.status != 'ERROR')
			{
				let updated_obj = result.records.map(item => {
					let { component_qty, automatic_usage, bom_step_id, ...rest } = item;
					let usage_method = automatic_usage ? 'Auto' : 'Manual';
					let matching_step = this.viewModel.workstation_flow_steps().find(step => step.bom_step_id === bom_step_id);
					let available_steps = this.viewModel.workstation_flow_steps().map(step => ({ name: step.name, bom_step_id: step.bom_step_id }));
					delete rest.stock_item_id;
					return { 
						...rest,
						stock_item_id: item.component_stock_item_id, 
						qty: ko.observable(component_qty),
						expanded: ko.observable(false),
						is_secondary_bom_component: false,
						is_combined_item: false,
						selected_usage_method: ko.observable(usage_method),
						selected_usage_step: ko.observable(matching_step ? matching_step.bom_step_id : null),
						available_steps: ko.observableArray(available_steps),
						sub_procedure: ko.observable(item.sub_procedure || false),
						supplier_price: ko.observable(item.supplier_price || 0)
					};
				});

				this.viewModel.components(updated_obj);
			}
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}

	async save_bom_stock_item (type = 'UPDATE', new_component)
	{
		try {
			let incomplete_steps = this.viewModel.workstation_flow_steps().filter(step => {
				let location_id = ko.unwrap(step.location_id);
				return !location_id || location_id === '';
			});
	
			if (incomplete_steps.length > 0)
			{
				let step_names = incomplete_steps.map(step => ko.unwrap(step.name)).join(', ');
				let message = `The following step(s) do not have a workstation selected: ${step_names}. Please select a workstation for each step before saving.`;
	
				Grape.alerts.alert({ type: 'warning', title: 'Missing Workstations', message: message });
				return;
			}

			let steps_missing_work_order = this.viewModel.workstation_flow_steps().filter(step => {
				return !ko.unwrap(step.work_order_nr);
			});
	
			if (steps_missing_work_order.length > 0)
			{
				let step_names = steps_missing_work_order.map(step => ko.unwrap(step.name)).join(', ');
				let message = `The following step(s) do not have a Work Order Number selected: ${step_names}. Please select a Work Order Number for each step before saving.`;
	
				Grape.alerts.alert({ type: 'warning', title: 'Missing Work Order Numbers', message: message });
				return;
			}

			let { days, hours, minutes } = ko.toJS(this.viewModel.lead_time());
			let obj = {
				...this.viewModel.selected_version(),
				lead_time: `${days} days ${hours} hours ${minutes} minutes`,
				instructions: this.viewModel.editor.html.get(),
				components: ko.toJS(this.viewModel.components()).map(component => ({
					stock_item_id: component.stock_item_id,
					qty: component.qty,
					selected_usage_method: component.selected_usage_method,
					selected_usage_step: component.selected_usage_step,
					sub_procedure: component.sub_procedure
				})),
				steps: {
					add: this.viewModel.workstation_flow_steps().map(step => {
						let predependencies = ko.unwrap(step.predependencies) || [];
						if (!Array.isArray(predependencies))
							predependencies = [predependencies];

						let { days: step_days, hours: step_hours, minutes: step_minutes } = ko.toJS(step.duration_per_item);
						let duration_string = `${step_days} days ${step_hours} hours ${step_minutes} minutes`;
	
						return {
							bom_step_id: ko.unwrap(step.bom_step_id),
							step_nr: ko.unwrap(step.step_nr),
							bom_id: this.viewModel.bom_id(),
							location_id: ko.unwrap(step.location_id),
							name: ko.unwrap(step.name),
							predependencies: predependencies,
							instructions: ko.unwrap(step.instructions),
							work_order_nr: ko.unwrap(step.work_order_nr),
							duration: duration_string
						};
					}),
					remove: this.viewModel.removed_steps().map(step => ({
						bom_step_id: ko.unwrap(step.bom_step_id)
					}))
				}
			};

			if (type == 'INSERT')
			{
				delete obj.bom_id;
				obj.version_date = this.viewModel.new_version().version_date;
				obj.version_name = this.viewModel.new_version().version_name;
				obj.version = this.viewModel.new_version().version;
			}

			if (new_component)
				obj.components.push(new_component);

			let components_without_usage_step = obj.components.filter(
				component => !component.selected_usage_step && component.selected_usage_method !== 'Manual'
			);
			
			if (components_without_usage_step.length > 0)
			{
				let response = await Grape.alerts.confirm({
					title: 'Missing Usage Steps',
					message: 'Some components do not have a usage step linked. Do you want to proceed?',
					type: 'warning'
				});
	
				if (!response)
					return;
			}

			let result = await Grape.fetches.postJSON("/api/stock-management/bom", obj);

			if (result.status == 'OK')
			{
				Grape.alerts.alert({ type: 'success', title: 'Success', message: 'Successfully saved Bill of materials' });

				this.viewModel.removed_steps([]);
				this.viewModel.edit_mode(false);
				await this.update_data();
			}
			else
				throw new Error(result.message);
		} catch (error) {
			Grape.alerts.alert({ title: 'Error', type: 'error', message: error });
			console.error(error);
		}
	}

	async load_bom_workstation_flow_steps ()
	{
		try {
			let result = await Grape.fetches.getJSON('api/record', {
				schema: 'stock',
				table: 'v_bom_step',
				filter: [
					{
						field: 'bom_id',
						operand: '=',
						value: this.viewModel.bom_id()
					}
				]
			});

			if (result.status === 'OK')
			{
				this.viewModel.work_orders([]);

				let x = await Promise.all(result.records.map(async step => {
					let existing_order_nr = this.viewModel.work_orders().find(order => order.value === step.work_order_nr);
					if (!existing_order_nr)
					{
						let new_work_order = {
							label: `Work Order #${step.work_order_nr}`,
							value: step.work_order_nr
						};
						this.viewModel.work_orders.push(new_work_order);
					}

					let parsed_duration = this.parseTimeString(step.duration);

					let step_data = {
						...step,
						step_nr: ko.observable(step.step_nr),
						name: ko.observable(step.name),
						available_steps: ko.observableArray([]),
						predependencies: ko.observableArray(step.predependencies),
						work_order_nr: ko.observable(step.work_order_nr),
						selected_work_order: ko.observable(this.viewModel.work_orders().find(order => order.value === step.work_order_nr) || {}),
						duration_per_item: ko.observable({
							days: ko.observable(parsed_duration.days || 0),
							hours: ko.observable(parsed_duration.hours || 0),
							minutes: ko.observable(parsed_duration.minutes || 0)
						}),
						instructions_files: ko.observableArray(await this.get_bom_step_instructions_files(step.bom_step_id) || [])
					};
	
					step_data.selected_work_order.subscribe(newValue => {
						step_data.work_order_nr(newValue.value);
					});
	
					return step_data;
				}));
	
				this.viewModel.workstation_flow_steps(x);

				this.viewModel.workstation_flow_steps().forEach(step => {
					let step_nr = ko.unwrap(step.step_nr);
					let available_steps = this.viewModel.workstation_flow_steps().filter(s => ko.unwrap(s.step_nr) < step_nr).map(s => ({
						step_nr: ko.unwrap(s.step_nr),
						name: ko.unwrap(s.name)
					}));
					step.available_steps(available_steps);
				});

				let highest_work_order_nr = Math.max(0, ...this.viewModel.workstation_flow_steps().map(step => step.work_order_nr() || 0));
				this.viewModel.highest_work_order_nr(highest_work_order_nr);				
			}
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}

	async load_bom_instructions_files ()
	{
		this.viewModel.instructions_files([]);

		let files = await window.Grape.StockUtils.load_fs_folder(`StockFiles/BOM/Instructions/${this.viewModel.bom_id()}`);
		this.viewModel.instructions_files(files);
	}

	async get_bom_step_instructions_files (bom_step_id)
	{
		if (bom_step_id == null)
			return [];

		let files = await window.Grape.StockUtils.load_fs_folder(`StockFiles/BOM/Steps/${bom_step_id}`);

		return files;
	}

	parseTimeString (timeString) 
	{
		let days = 0, hours = 0, minutes = 0;
	
		// might contain day or days
		if (timeString.includes('day'))
		{
			const parts = timeString.split(' ');
			days = parseInt(parts[0]);
	
			if (parts.length > 2)
			{
				const [hoursPart, minutesPart, secondsPart] = parts[2].split(':').map(Number);
				hours = hoursPart || 0;
				minutes = minutesPart || 0;
			}
		}
		else if (timeString.includes(':'))
		{
			const [hoursPart, minutesPart, secondsPart] = timeString.split(':').map(Number);
			hours = hoursPart || 0;
			minutes = minutesPart || 0;
		}
	
		return {
			days: days,
			hours: hours,
			minutes: minutes
		};
	}

	async select_version (version)
	{
		if (this.viewModel.selected_version() === version)
		{
			this.viewModel.selected_version(undefined)
			this.viewModel.editor.html.set('');
		}
		else
		{
			this.viewModel.selected_version(version);
			this.viewModel.editor.html.set(version.instructions || '');

			let lead_time = this.parseTimeString(version.lead_time);
			this.viewModel.lead_time().days(lead_time.days || 0);
			this.viewModel.lead_time().hours(lead_time.hours || 0);
			this.viewModel.lead_time().minutes(lead_time.minutes || 0);
			this.viewModel.bom_id(version.bom_id);
		}
	}

	teardown ()
	{
		clearTimeout(this.timer);
	}
}

export default {
	route: '[/stock_item/]si_production',
	page_id: 'si_production',
	page_class: ProductionPage,
	template: template
};