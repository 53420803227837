import template from './usage_orders.html';

class UsageOrderViewModel
{
	constructor (page)
	{
		this.page = page;
		this.loading = ko.observable(false);
	}
}

class UsageOrderPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new UsageOrderViewModel(this);
	}
}

export default {
	route: '/stock/order/usage_orders/',
	page_class: UsageOrderPage,
	template: template
}