import template from './workstation.html';

class WorkstationViewModel
{
	constructor (page)
	{
		this.page = page;
		this.location_id = ko.observable('');
		this.workstations = ko.observableArray([]);
		this.edit_mode = ko.observable(false);
		this.name = ko.observable();
		this.description = ko.observable();
		this.schedule = ko.observableArray([]);
		this.bom_items = ko.observableArray([]);
		this.instructions_files = ko.observableArray([]);
		this.detail_search = ko.observable();
		this.upload_file = ko.observable();
		this.locations = ko.observableArray([]);
		this.selected_destination = ko.observable();
		this.delete_workstation_visible = ko.observable(false);
		this.code = ko.observable();
		this.location_tag = ko.observableArray([{ name: undefined }]);

		this.workstation_default_values = ko.observable();
		this.edit_defaults_mode = ko.observable(false);

		if (Grape.currentSession.roles.includes('stock.super_user'))
			this.delete_workstation_visible(true);

		this.location_id.subscribe((newval) => {
			if (newval && (localStorage.getItem('workstation_setup_last_workstation') != newval || this.page.bindings.location_id === null))
			{
				localStorage.setItem('workstation_setup_last_workstation', newval);
				Grape.navigate(`/workstation/setup/${newval}`);
			}
		});

		this.total_working_hours_per_day = ko.computed(() => {
			return this.calc_hours_per_day(true);
		});

		this.total_non_working_hours_per_day = ko.computed(() => {
			return this.calc_hours_per_day(false);
		});

		this.total_working_hours_per_week = ko.computed(() => {
			let totals = this.total_working_hours_per_day();
			return Object.values(totals).reduce((a, b) => a + b, 0);
		});

		this.total_non_working_hours_per_week = ko.computed(() => {
			let totals = this.total_non_working_hours_per_day();
			return Object.values(totals).reduce((a, b) => a + b, 0);
		});
	}

	async copy_schedule_click ()
	{
		try {
			let schedule_data = ko.toJS(this.schedule); 
			let schedule_json = JSON.stringify(schedule_data);
	
			await navigator.clipboard.writeText(schedule_json);

			Grape.alerts.alert({
				type: 'success',
				title: 'Copied',
				message: 'Schedule copied to your clipboard!'
			});
		} 
		catch (err) {
			console.error(err);

			Grape.alerts.alert({
				type: 'error',
				title: 'Error',
				message: 'Failed to copy schedule to clipboard!'
			});
		}
	}

	async paste_schedule_click ()
	{
		try {
			let confirm = await Grape.alerts.confirm({ type: 'warning', title: 'Confirm', message: 'Are you sure? This will override any existing schedule details!' });
			if (!confirm)
				return;

			let clipboard_content = await navigator.clipboard.readText();

			if (!clipboard_content)
			{
				Grape.alerts.alert({
					type: 'warning',
					title: 'No Data',
					message: 'Clipboard is empty or invalid!'
				});
				return;
			}
	
			let parsed_schedule = JSON.parse(clipboard_content);

			if (!Array.isArray(parsed_schedule))
			{
				Grape.alerts.alert({
					type: 'warning',
					title: 'Invalid Data',
					message: 'Clipboard content is not valid schedule data!'
				});
				return;
			}

			this.schedule.removeAll();

			parsed_schedule.forEach(item => {
				let new_hours = this.set_hours(item.working, {
					description: item.description,
					sunday: item.sunday,
					monday: item.monday,
					tuesday: item.tuesday,
					wednesday: item.wednesday,
					thursday: item.thursday,
					friday: item.friday,
					saturday: item.saturday,
					is_new: true
				});
				this.schedule.push(new_hours);
			});

			Grape.alerts.alert({
				type: 'success',
				title: 'Pasted',
				message: 'Schedule pasted!'
			});
		} 
		catch (error) {
			console.error(error);

			Grape.alerts.alert({
				type: 'error',
				title: 'Paste Error',
				message: 'Could not paste schedule data from clipboard!'
			});
		}
	}

	calc_hours_per_day (is_working)
	{
		let totals = {
			sunday: 0,
			monday: 0,
			tuesday: 0,
			wednesday: 0,
			thursday: 0,
			friday: 0,
			saturday: 0,
		};

		this.schedule().forEach((item) => {
			if (item.working() === is_working)
			{
				['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'].forEach((day) => {
					let start = item[day].start_time();
					let end = item[day].end_time();
					if (start && end)
					{
						let duration = this.calc_duration(start, end);
						totals[day] += duration;
					}
				});
			}
		});

		return totals;
	}

	calc_duration (start_time, end_time)
	{
		let [start_hours, start_minutes] = start_time.split(':').map(Number);
		let [end_hours, end_minutes] = end_time.split(':').map(Number);

		let start_total_minutes = start_hours * 60 + start_minutes;
		let end_total_minutes = end_hours * 60 + end_minutes;

		let duration = (end_total_minutes - start_total_minutes) / 60;

		// Handle overnight shifts
		if (duration < 0)
			duration += 24;

		return duration;
	}

	async remove_schedule (schedule)
	{
		let res = await Grape.alerts.confirm({ title: 'Confirm', type: 'warning', message: 'Are you sure you want to remove this schedule?' });
		
		if (res)
			this.schedule.remove(schedule);
	}

	addLocationTag () 
	{
		this.location_tag.push({ name: undefined });
	}

	removeLocationTag (v)
	{
		this.location_tag.remove(v);
	}

	async upload_click () 
	{
		const file_input = document.getElementById('workstation_attachment_upload');
		let files = file_input.files;

		let response = await window.Grape.StockUtils.upload_fs_file(files, `StockFiles/Locations/${this.location_id()}`)

		if (response)
		{
			Grape.alerts.alert({ type: 'success', title: 'Success', message: 'Instruction(s) successfully uploaded!' });
			this.page.updateData();
		}
	}

	async upload_bom_location_click (bom_item) 
	{
		if (!bom_item.bom_location_id)
			return;

		const file_input = document.getElementById('w_bom_attachment_upload');
		let files = file_input.files;

		let response = await window.Grape.StockUtils.upload_fs_file(files, `StockFiles/BOM/Locations/${bom_item.bom_location_id}`)

		if (response)
		{
			Grape.alerts.alert({ type: 'success', title: 'Success', message: 'Instruction(s) successfully uploaded!' });
			this.page.updateData();
		}
	}

	async btn_delete_instruction_click (file) 
	{
		let response = await Grape.alerts.confirm({
			message: 'Are you sure you want to delete this instruction file?',
			title: 'Delete File',
			type: 'danger'
		})

		if (response)
		{
			response = await window.Grape.StockUtils.delete_fs_file(file);

			if (response)
			{
				Grape.alerts.alert({ type: 'success', title: 'Success', message: 'Document successfully deleted!' });
				this.page.updateData();
			}
		}
	}

	async save_workstation_click ()
	{
		if (!this.selected_destination() || !this.name())
		{
			Grape.alerts.alert({ 
				type: 'warning', 
				title: 'Empty Fields', 
				message: 'Please ensure that the name and description fields are populated and that a destination location is selected!' 
			});

			return;
		}

		await this.page.save_workstation();
		this.edit_mode(false);
	}

	new_workstation_click ()
	{
		Grape.navigate(`/workstation/setup`);
	}

	cancel_edit_workstation_click ()
	{
		let new_items = this.schedule().filter(item => item.is_new());
		new_items.forEach(item => this.schedule.remove(item));
		this.edit_mode(false);
	}

	edit_workstation_click ()
	{
		this.edit_mode(true);
	}

	async delete_workstation_click ()
	{
		if (this.workstations().length < 2)
		{
			Grape.alerts.alert({ type: 'error', title: 'Error', message: 'Cannot delete a workstation if no other workstations exist! Please create a new workstation first.' });
			return;
		}

		let result = await Grape.alerts.confirm({ 
			type: 'danger', 
			title: 'Delete Workstation?', 
			message: 'Are you sure you want to delete this workstation? It will require a replacement workstation for any BOM steps linked to it!' 
		});
		if (!result) return;
		
		let dialog_result = await Grape.dialog.open('DeleteWorkstation', { location_id: this.location_id(), workstation_options: this.workstations() })

		if (dialog_result)
		{
			let replacement_location_id = dialog_result;

			try {
				let options = { 
					location_id: this.location_id(),
					replacement_location_id: replacement_location_id
				};
				let result = await Grape.fetches.deleteJSON('/api/stock-management/workstation', options);
		
				if (result.status === 'OK')
				{
					Grape.alerts.alert({ type: 'success', title: 'Deleted', message: 'Workstation successfully deleted' });
					Grape.navigate(`/workstation/setup`);
				}
				else
					throw new Error(result.message || result.code);
			} catch (error) {
				Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
				console.error('Error deleting workstation:', error);
			}

			return;
		}
		else
			return;
	}

	detail_search_click ()
	{
		Grape.alerts.alert({ type: 'info', title: 'Search', message: 'TODO implement this' });
	}

	show_working_hours_btn ()
	{
		let new_hours = this.set_hours(true);
		this.schedule.push(new_hours);
	}

	show_non_working_hours_btn ()
	{
		let new_hours = this.set_hours(false);
		this.schedule.push(new_hours);
	}

	set_hours (working = true, data = {})
	{
		let hours = {
			description: ko.observable(data.description || ''),
			working: ko.observable(working),
			sunday: {
				start_time: ko.observable(data.sunday ? data.sunday.start_time : null),
				end_time: ko.observable(data.sunday ? data.sunday.end_time : null)
			},
			monday: {
				start_time: ko.observable(data.monday ? data.monday.start_time : null),
				end_time: ko.observable(data.monday ? data.monday.end_time : null)
			},
			tuesday: {
				start_time: ko.observable(data.tuesday ? data.tuesday.start_time : null),
				end_time: ko.observable(data.tuesday ? data.tuesday.end_time : null)
			},
			wednesday: {
				start_time: ko.observable(data.wednesday ? data.wednesday.start_time : null),
				end_time: ko.observable(data.wednesday ? data.wednesday.end_time : null)
			},
			thursday: {
				start_time: ko.observable(data.thursday ? data.thursday.start_time : null),
				end_time: ko.observable(data.thursday ? data.thursday.end_time : null)
			},
			friday: {
				start_time: ko.observable(data.friday ? data.friday.start_time : null),
				end_time: ko.observable(data.friday ? data.friday.end_time : null)
			},
			saturday: {
				start_time: ko.observable(data.saturday ? data.saturday.start_time : null),
				end_time: ko.observable(data.saturday ? data.saturday.end_time : null)
			},
			is_new: ko.observable(data.is_new !== undefined ? data.is_new : true)
		};
	
		['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].forEach((day) => {
			this.setup_time_subscriptions(hours, day);
		});
	
		return hours;
	}

	is_valid_time_range (start_time, end_time)
	{
		let [start_hours, start_minutes] = start_time.split(':').map(Number);
		let [end_hours, end_minutes] = end_time.split(':').map(Number);
		let start_total_minutes = start_hours * 60 + start_minutes;
		let end_total_minutes = end_hours * 60 + end_minutes;

		return start_total_minutes < end_total_minutes;
	}

	setup_time_subscriptions (hours, day)
	{
		hours[day].start_time.subscribe(async (x) => {
			let end_time = hours[day].end_time();
			if (x && end_time)
			{
				if (!this.is_valid_time_range(x, end_time))
				{
					Grape.alerts.alert({ type: 'error', title: 'Invalid Time', message: 'Start time is later than or equal to end time. Please enter valid times.' });
					hours[day].start_time(null);
					hours[day].end_time(null);
					return;
				}
				await this.check_overlap(day, x, end_time, hours);
			}
		});

		hours[day].end_time.subscribe(async (y) => {
			let start_time = hours[day].start_time();
			if (start_time && y)
			{
				if (!this.is_valid_time_range(start_time, y))
				{
					Grape.alerts.alert({ type: 'error', title: 'Invalid Time', message: 'End time is earlier than or equal to start time. Please enter valid times.' });
					hours[day].start_time(null);
					hours[day].end_time(null);
					return;
				}
				await this.check_overlap(day, start_time, y, hours);
			}
		});
	}

	async check_overlap (day, new_start_time, new_end_time, current_hours)
	{
		if (!new_start_time || !new_end_time) return;
	
		// Convert new times to total minutes
		let [new_start_hours, new_start_minutes] = new_start_time.split(':').map(Number);
		let [new_end_hours, new_end_minutes] = new_end_time.split(':').map(Number);
		let new_start_total = new_start_hours * 60 + new_start_minutes;
		let new_end_total = new_end_hours * 60 + new_end_minutes;
	
		// Handle overnight shifts
		if (new_end_total <= new_start_total)
			new_end_total += 24 * 60;
	
		let overlapping_items = [];
	
		for (let item of this.schedule())
		{
			if (item === current_hours) continue;
	
			let existing_start_time = item[day].start_time();
			let existing_end_time = item[day].end_time();
	
			if (existing_start_time && existing_end_time)
			{
				let [existing_start_hours, existing_start_minutes] = existing_start_time.split(':').map(Number);
				let [existing_end_hours, existing_end_minutes] = existing_end_time.split(':').map(Number);
				let existing_start_total = existing_start_hours * 60 + existing_start_minutes;
				let existing_end_total = existing_end_hours * 60 + existing_end_minutes;
	
				// Handle overnight shifts
				if (existing_end_total <= existing_start_total)
					existing_end_total += 24 * 60;
	
				// Check for overlap
				if ((new_start_total < existing_end_total) && (new_end_total > existing_start_total))
					overlapping_items.push(item);
			}
		}
	
		if (overlapping_items.length > 0)
			await this.handle_overlap(current_hours, day, overlapping_items);
	}

	async handle_overlap (current_hours, day, overlapping_items)
	{
		let items = [];

		for (let item of overlapping_items)
			items.push({ item: `Schedule: ${item.description()} | Time: from ${item[day].start_time()} to ${item[day].end_time()}` });

		let response = await Grape.dialog.open('WorkstationScheduleOverlap', { day: day, items: items});

		if (!response)
		{
			// User chose not to proceed; reset the time
			current_hours[day].start_time(null);
			current_hours[day].end_time(null);
		}
	}
}

class WorkstationClass
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new WorkstationViewModel(this);

		if (this.bindings.location_id)
			this.viewModel.location_id(this.bindings.location_id);
		else
			this.viewModel.edit_mode(true);

		if (this.bindings.name)
			this.viewModel.name(this.bindings.name);

		if (this.bindings.code)
			this.viewModel.code(this.bindings.code);
	}

	async init ()
	{
		document.title = 'Workstation Setup';
		let locations = await Grape.cache.fetch('ActiveLocations');

		this.viewModel.workstation_default_values({
			setup_time: ko.observable(this.set_interval("0 days 0 hours 0 minutes")),
			cleanup_time: ko.observable(this.set_interval("0 days 0 hours 0 minutes")),
			cost_per_hr: ko.observable(0),
			min_capacity: ko.observable(0),
			max_capacity: ko.observable(0)
		});

		this.viewModel.locations(locations);

		if (!this.bindings.location_id)
		{
			let default_loc = locations.find(loc => loc.name === 'Factory Area (Default)');
			if (default_loc)
				this.viewModel.selected_destination(default_loc);
		}

		try {
			let result = await Grape.fetches.getJSON('api/record', {
				schema: 'stock',
				table: 'v_workstation',
				filter: []
			});

			if (result.status === 'OK')
				this.viewModel.workstations(result.records);
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}

	async updateData ()
	{
		if (this.viewModel.location_id() === '')
			this.viewModel.edit_mode(true);
		else
		{
			this.viewModel.edit_mode(false);
			await this.load_workstation(this.viewModel.location_id());
			await this.load_workstation_instructions();
		}
	}

	async load_workstation_instructions ()
	{
		this.viewModel.instructions_files([]);

		let files = await window.Grape.StockUtils.load_fs_folder(`StockFiles/Locations/${this.viewModel.location_id()}`);
		this.viewModel.instructions_files(files);
	}

	async load_workstation (location_id)
	{
		try {
			let result = await Grape.fetches.getJSON('api/record', {
				schema: 'stock',
				table: 'v_workstation',
				filter: [{
					field: 'location_id',
					operand: '=',
					value: location_id
				}]
			});

			if (result.status === 'OK')
			{
				let workstation = result.records[0];
				this.viewModel.location_id(workstation.location_id);
				this.viewModel.name(workstation.name);
				this.viewModel.code(workstation.code);
				this.viewModel.description(workstation.description);
				this.viewModel.selected_destination(this.viewModel.locations().find(loc => loc.location_id === workstation.destination_location_id));
				this.viewModel.bom_items(workstation.bom_items || [])

				if (workstation.schedule)
				{
					let schedule = workstation.schedule.map(item => {
						let schedule_item = this.viewModel.set_hours(item.working, {
							description: item.description,
							sunday: item.sunday,
							monday: item.monday,
							tuesday: item.tuesday,
							wednesday: item.wednesday,
							thursday: item.thursday,
							friday: item.friday,
							saturday: item.saturday,
							is_new: false
						});

						return schedule_item;
					});
	
					this.viewModel.schedule(schedule);
				}

				if (workstation.tags && workstation.tags != '')
				{
					let tags = workstation.tags.map(name => ({ name }));
					this.viewModel.location_tag(tags);
				}

				this.viewModel.workstation_default_values().setup_time(this.set_interval(workstation.setup_time || 0));
				this.viewModel.workstation_default_values().cleanup_time(this.set_interval(workstation.cleanup_time || 0));
				this.viewModel.workstation_default_values().cost_per_hr(workstation.cost_per_hr || 0);
				this.viewModel.workstation_default_values().min_capacity(workstation.min_capacity || 0);
				this.viewModel.workstation_default_values().max_capacity(workstation.max_capacity || 0);
			}
			else
				throw new Error(result.message || result.code);

			result = await Grape.fetches.getJSON('api/record', {
				schema: 'stock',
				table: 'v_workstation_bom_items',
				filter: [{
					field: 'location_id',
					operand: '=',
					value: location_id
				}]
			});

			if (result.status === 'OK')
			{
				for (let bom_item of result.records)
				{
					this.viewModel.bom_items.push({
						name: bom_item.description,
						bom_id: bom_item.bom_id,
						bom_location_id: bom_item.bom_location_id,
						version: bom_item.version || '',
						version_name: bom_item.version_name || '',
						duration_per_item: ko.observable(this.set_interval(bom_item.duration_per_item || bom_item.step_durations_sum)),
						dependent_workstations: bom_item.dependent_workstations || 5,
						setup_time: ko.observable(
							bom_item.setup_time 
								? this.set_interval(bom_item.setup_time) 
								: this.set_interval(this.get_interval_string(this.viewModel.workstation_default_values().setup_time()))
						),
						cleanup_time: ko.observable(
							bom_item.cleanup_time 
								? this.set_interval(bom_item.cleanup_time) 
								: this.set_interval(this.get_interval_string(this.viewModel.workstation_default_values().cleanup_time()))
						),
						cost_per_hr: ko.observable(bom_item.cost_per_hr || this.viewModel.workstation_default_values().cost_per_hr()),
						max_capacity: ko.observable(bom_item.max_capacity || this.viewModel.workstation_default_values().max_capacity()),
						min_capacity: ko.observable(bom_item.min_capacity || this.viewModel.workstation_default_values().min_capacity()),
						instructions_files: ko.observableArray(await this.get_item_instructions_files(bom_item.bom_location_id))
					});
				}
			}
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}

	async get_item_instructions_files (bom_location_id)
	{
		if (bom_location_id == null)
			return [];

		let files = await window.Grape.StockUtils.load_fs_folder(`StockFiles/BOM/Locations/${bom_location_id}`);

		return files;
	}

	async save_workstation ()
	{
		try 
		{
			let bom_items = ko.mapping.toJS(this.viewModel.bom_items());

			if (!this.checkForNegatives(ko.mapping.toJS(this.viewModel.bom_items())))
				throw new Error('No negative values should be provided in the workstation details');

			for (let bom_item of bom_items)
			{
				bom_item.duration_per_item = this.get_interval_string(bom_item.duration_per_item);
				bom_item.setup_time = this.get_interval_string(bom_item.setup_time);
				bom_item.cleanup_time = this.get_interval_string(bom_item.cleanup_time);
			}

			if (!this.checkForNegatives(ko.mapping.toJS(this.viewModel.workstation_default_values())))
				throw new Error('No negative values should be provided in the workstation defaults');

			let workstation_defaults = ko.mapping.toJS(this.viewModel.workstation_default_values());
			workstation_defaults.setup_time = this.get_interval_string(workstation_defaults.setup_time);
			workstation_defaults.cleanup_time = this.get_interval_string(workstation_defaults.cleanup_time);

			let options = {
				name: this.viewModel.name(),
				description: this.viewModel.description(),
				code: this.viewModel.code(),
				tags: this.viewModel.location_tag().map(tag => tag.name),
				destination_location_id: this.viewModel.selected_destination().location_id,
				workstation_defaults: workstation_defaults,
				bom_items: bom_items,
				schedule: ko.mapping.toJS(this.viewModel.schedule())
			};

			if (this.viewModel.location_id() != "")
				options.location_id = this.viewModel.location_id();

			let result = await Grape.fetches.postJSON('/api/stock-management/workstation', options);

			if (result.status == 'OK')
			{
				Grape.alerts.alert({ type: 'success', title: 'Success', message: 'Workstation successfully saved' });
				Grape.cache.refresh('Workstations');
				Grape.cache.refresh('ActiveWorkstations');
				Grape.cache.refresh('ActiveLocations');
				
				Grape.navigate(`/workstation/setup/${result.location_id}`);
			}
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		}
	}

	set_interval (interval)
	{
		let parsed_duration = this.parseTimeString(interval);

		return {
			days: ko.observable(parsed_duration.days || 0),
			hours: ko.observable(parsed_duration.hours || 0),
			minutes: ko.observable(parsed_duration.minutes || 0)
		}
	}

	parseTimeString (timeString) 
	{
		timeString = timeString.toString();
		let days = 0, hours = 0, minutes = 0;
	
		// might contain day or days
		if (timeString.includes('day'))
		{
			const parts = timeString.split(' ');
			days = parseInt(parts[0]);
	
			if (parts.length > 2)
			{
				const [hoursPart, minutesPart, secondsPart] = parts[2].split(':').map(Number);
				hours = hoursPart || 0;
				minutes = minutesPart || 0;
			}
		}
		else if (timeString.includes(':'))
		{
			const [hoursPart, minutesPart, secondsPart] = timeString.split(':').map(Number);
			hours = hoursPart || 0;
			minutes = minutesPart || 0;
		}
	
		return {
			days: days,
			hours: hours,
			minutes: minutes
		};
	}

	get_interval_string (interval_obj)
	{
		const { days: step_days, hours: step_hours, minutes: step_minutes } = ko.toJS(interval_obj);
		return `${step_days ? step_days : 0} days ${step_hours ? step_hours : 0} hours ${step_minutes ? step_minutes : 0} minutes`;
	}

	checkForNegatives(obj)
	{
		let valid = this.findNegative(obj);

		if (!valid.isValid)
			console.error(`${valid.negativeKey} is not a valid`);

		return valid.isValid;
	}
	
	findNegative(obj, parentKey = '') 
	{
		if (typeof obj === 'object' && obj !== null)
		{
			for (const key in obj) 
			{
				const fullKey = parentKey ? `${parentKey}.${key}` : key;
				const value = obj[key];
	
				if (typeof value === 'object') 
				{
					let result = this.findNegative(value, fullKey);
					if (!result.isValid) return result;
				}
				else
				{
					const numValue = parseFloat(value);
					if (!isNaN(numValue) && numValue < 0)
						return { isValid: false, negativeKey: fullKey };
				}
			}
		}

		return { isValid: true, negativeKey: null }; // Add this return statement to avoid undefined errors
	}
}

export default {
	route: '[/]workstation/setup/:location_id',
	page_class: WorkstationClass,
	template: template
}