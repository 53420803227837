import template from './batched_sales_orders.html';

class BatchedSalesOrderViewModel
{
	constructor (page)
	{
		this.page = page;
	}
}

class BatchedSalesOrderPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new BatchedSalesOrderViewModel(this);
	}
}

export default {
	route: '/stock/order/batched_sales_orders/',
	page_class: BatchedSalesOrderPage,
	template: template
}
