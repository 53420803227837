import template from './ko-stock-order-items.html';

class OrderItemsComponentViewModel
{
	constructor (params)
	{
		this.params = params;

		this.available_items_object = ko_helper.safe_observableArray(params.available_items_object);
		this.initial_selected_items = ko_helper.safe_observableArray(params.initial_selected_items || []);

		this.selected_items = ko_helper.safe_observableArray([]);
		this.order_items = ko_helper.safe_observableArray(params.order_items || []);
		this.type = ko_helper.safe_observable(params.type);
		this.delivery_date = ko_helper.safe_observable(params.delivery_date);

		this.can_send_delivery = ko_helper.safe_observable(params.can_send_delivery || false);
		this.can_receive_delivery = ko_helper.safe_observable(params.can_receive_delivery || false);
		this.sent_outstanding_html = ko.observable('<span>Outstanding</span>');
		this.receive_outstanding_html = ko.observable('<span>Outstanding</span>');

		this.can_send_delivery.subscribe(() => this.update_outstanding_column_headings() );
		this.can_receive_delivery.subscribe(() => this.update_outstanding_column_headings() );

		this.selected_items.subscribe((newSelectedItems) => {
			// If selected item is not in order items, add it
			for (let selected_item of newSelectedItems) 
			{
				let has_product = this.order_items().find((item) => { return item.stock_item_id === selected_item.stock_item_id; });
				let item = this.available_items_object().find((item) => { return item.stock_item_id == selected_item.stock_item_id; });

				if (item && !has_product) 
				{
					let arr = this.order_items();
					arr.push({
						stock_item_id: item.stock_item_id,
						description: item.description,
						ppu: ko.observable(item.current_price),
						qty: ko.observable(0),
						expected_receive_dates: ko.observableArray([{ date: ko.observable(this.delivery_date()), qty: ko.observable(0) }])
					});
					this.order_items(arr);
				}
			}

			// If there is an order item not in the selected items, remove it
			let order_items = this.order_items();
			let new_order_items = [];
			for (let order_item of order_items)
			{
				let is_selected = newSelectedItems.find((item) => { return item.stock_item_id == order_item.stock_item_id });

				if (is_selected)
					new_order_items.push(order_item);
			}
			this.order_items(new_order_items);
		});

		this.initial_selected_items.subscribe((new_items) => this.selected_items(new_items) );

		this.delivery_date.subscribe((new_date) => {
			this.order_items().forEach((item) => {
				item.expected_receive_dates().forEach((val) => val.date(new_date) );
			});
		});

		this.total_value = ko.computed(() => {
			let total = 0;
			this.order_items().forEach((item) => {
				total += item.ppu() * item.expected_receive_dates().reduce((acc, curr) => acc + (parseInt(curr.qty()) || 0), 0);
			});
			return total;
		});
	
		this.total_qty = ko.computed(() => {
			let qty = 0;
			this.order_items().forEach((item) => {
				qty += item.expected_receive_dates().reduce((acc, curr) => acc + (parseInt(curr.qty()) || 0), 0);
			});
			return qty;
		});
	}

	column_visibility ()
	{
		if (this.type() == 'create')
			return false;
		else
			return true;
	}

	view_mode () 
	{
		return this.type() === 'view';
	}

	create_mode () 
	{
		return this.type() === 'create';
	}

	async btn_remove_item_click (item) 
	{
		let result = await Grape.alerts.confirm(
		{
			type: 'warning',
			message: 'Are you sure you want to remove this order item?',
			title: 'Remove Order Item', 
		});

		if (result)
		{
			this.order_items.remove(item);
			let index = -1;
			let count = 0;
			let items = this.selected_items();
			items.forEach((s_item) => {
				if (s_item.stock_item_id == item.stock_item_id)
					index = count;
				count++;
			});
			items.splice(index, 1);
			this.selected_items(items);
		}
	}

	async btn_remove_delivery_date_click (item, delivery)
	{
		// Check if this is the last delivery for the order item
		let single_delivery = item.expected_receive_dates().length === 1;
		let confirm_message = single_delivery ? 'Are you sure you want to remove this order item?' : 'Are you sure you want to remove this delivery?';
	
		let result = await Grape.alerts.confirm({
			type: 'warning',
			message: confirm_message,
			title: single_delivery ? 'Remove Order Item' : 'Remove Delivery',
		});
	
		if (result)
		{
			item.expected_receive_dates.remove(delivery);
			if (item.expected_receive_dates().length === 0) 
			{
				this.order_items.remove(item);

				let index = -1;
				let count = 0;
				let items = this.selected_items();
				items.forEach((s_item) => {
					if (s_item.stock_item_id == item.stock_item_id)
						index = count;
					count++;
				});
				items.splice(index, 1);
				this.selected_items(items);
			}
		}
	}

	btn_add_extra_delivery_click (item)
	{
		item.expected_receive_dates.push({date: ko.observable(this.delivery_date()), qty: ko.observable(0)});
	}

	update_outstanding_column_headings()
	{
		if (this.can_send_delivery() && this.can_receive_delivery())
		{
			this.sent_outstanding_html('<span>Outstanding</span><br /><span style="font-size: small;">(Sent)</span>');
			this.receive_outstanding_html('<span>Outstanding</span><br /><span style="font-size: small;">(Received)</span>');
		}

	}
}

export default {
	name: 'ko-stock-order-items',
	viewModel: OrderItemsComponentViewModel,
	module_type: 'ko',
	template: template
}