import template from './view_transaction.html';

class ViewTransactionDialogViewModel
{
	constructor (page)
	{
		this.page = page;
		this.order_id = ko.observable();
		this.transaction_data = ko.observable({});
		this.order_transaction_id = ko.observable();
		
		this.dialog_header = ko.pureComputed(() => {
			if (!this.transaction_data() || !this.transaction_data().stock_movements) 
				return 'Transaction Details';
	
			let type = this.transaction_data().receive ? 'RECEIVE at ' : 'SEND from ';
			let location = this.transaction_data().location_name || '';
	
			return `Transaction Details - ${type} ${location}`;
		});
	}

	btn_back_click ()
	{
		this.page.close();
	}

	formatted_date (date)
	{
		return date ? moment(date).format('YYYY-MM-DD') : "-";
	}
}

class ViewTransactionDialog
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new ViewTransactionDialogViewModel(this);
		this.viewModel.order_id(bindings.order_id);
		this.viewModel.order_transaction_id(bindings.order_transaction_id);
		this.parentViewModel = bindings.page;
	}

	init ()
	{
		this.get_transaction_details(this.viewModel.order_transaction_id());
	}

	async get_transaction_details (order_transaction_id)
	{
		let transaction_data = await Grape.fetches.getJSON(`/api/stock-management/order-transaction`, {
			order_transaction_id: order_transaction_id
		});
		this.viewModel.transaction_data(transaction_data.order_transaction); 
	} 
}

export default {
	name: 'ViewTransactionDialog',
	dialog_class: ViewTransactionDialog,
	template: template
}
