// CACHES
import StockItemTags from './stock-item-tags.js';
import StockUsers from './stock-users.js';
import OrderTypes from './order-types.js';
import OrderStatuses from './order-statuses.js';
import MovementTypes from './movement-types.js';
import StockItems from './stock-items.js';
import ActiveStockItems from './active-stock-items.js';
import UOM from './uom.js';
import StockGroups from './stock-groups.js';
import activeWorkstations from './active-workstations.js';
import workstations from './workstations.js';
import carriedStockItems from './carried-stock-items.js';
import activeCarriedStockItems from './active-carried-stock-items.js';

// Locations
import Locations from './locations/locations.js';
import ActiveLocations from './locations/active-locations.js';
import UserLocations from './locations/user-locations.js';
import CreateOrderLocations from './create-order-locations.js';
import allLocations from './locations/all-locations.js';
import allActiveLocations from './locations/all-active-locations.js';

export default [
	ActiveLocations,
	activeWorkstations,
	ActiveStockItems,
	activeCarriedStockItems,
	carriedStockItems,
	StockItems,
	StockGroups,
	MovementTypes,
	StockItemTags,
	UserLocations,
	CreateOrderLocations,
	Locations,
	OrderTypes,
	OrderStatuses,
	MovementTypes,
	StockUsers,
	workstations,
	UOM,
	allLocations,
	allActiveLocations
];
