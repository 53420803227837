import template from './create_production_order.html';

class CreateProductionOrderViewModel
{
	constructor (page)
	{
		this.page = page;
		this.loading = ko.observable(true);
		this.order = ko.observableArray([]);
		this.type = ko.observable(this.page.bindings.type != null ? this.page.bindings.type : 'create');
		this.order_items_type = ko.observable(this.page.bindings.type);
		this.order_id = ko.observable(this.page.bindings.order_id || '');
		this.reload_production_schedule = ko.observable(false);
		this.reference_numbers = ko.observableArray([]);
		this.new_reference_number = ko.observable();
		this.order_pricing = ko.observableArray([]);
		this.order_fields = ko.observableArray([]);
		this.order_audit_log = ko.observableArray([]);
		this.locations = ko.observableArray([]);
		this.filtered_locations = ko.observableArray([]);
		this.delivery_docs = ko.observableArray([]);
		this.delivery_doc = ko.observable();
		this.stock_order_items = ko.observableArray([]);
		this.initial_selected_items = ko.observableArray([]);
		this.components_table_records = ko.observableArray([]);
		this.reload_associated_orders = ko.observable(false);

		this.order_types = ko.observableArray([]);
		this.order_statuses = ko.observableArray([]);
		this.accept_status = ko.observable();
		this.reject_status = ko.observable();

		this.selected_type = ko.observable();
		this.selected_status = ko.observable();
		this.selected_destination = ko.observable();
		this.delivery_date = ko.observable();
		this.order_date = ko.observable();
		this.order_number = ko.observable();
		this.order_note = ko.observable();
		this.order_documents = ko.observableArray([]);
		this.order_transactions = ko.observableArray([]);
		this.edited_tax = ko.observableArray([]);

		this.can_send_delivery = ko.observable(false);
		this.can_receive_delivery = ko.observable(false);

		this.filtered_destination_locations = ko.observableArray([]);

		this.can_reject_order = ko.observable(false);

		this.type.subscribe(() => this.update_order_items_type() );

		this.selected_type.subscribe((type) => {
			this.selected_status(null);
			let initial_status;
			
			if (type && !this.order_id() && !this.selected_status())
				initial_status = type.statuses.find((x) => x.initial);
		
			let filtered_statuses;
			
			if (initial_status)
				filtered_statuses = [initial_status];
			else if (type)
			{
				let selected_status = this.order().order_status;
				let current_status = type.statuses.find(status => status.status === selected_status);
				filtered_statuses = type.statuses.filter((x) => {
					return x.status === selected_status || (current_status && current_status.allowed_status.includes(x.status));
				});
			}
			
			this.order_statuses(filtered_statuses);
			if (initial_status) 
				this.selected_status(initial_status);
		});

		this.selected_destination.subscribe((newValue) => {
			if (newValue && newValue.location_type != 'Supplier')
				this.can_receive_delivery(true);
		});

		this.show_order_nr_on_create = ko.computed(() => {
			if (Grape.config.public_settings.display_order_nr_on_create && this.type() == 'create')
				return true;
			else 
				return false;
		});

		this.loading(false);
	}

	btn_download_grn_click (item) 
	{
		window.open(`/api/stock-management/GRN/generate?order_id=${this.order_id()}&order_transaction_id=${item.order_transaction_id}`);
	}

	// Event handlers
	btn_toggle_edit_click (row) 
	{
		if (row.isEditing()) 
		{
			let taxes = {};
			
			for (let i = 0; i < row.taxes.length; i++)
				row.taxes[i].type = 'tax_perc';
			taxes = row.taxes;

			this.edited_tax(taxes);
			this.page.save_order();
		}

		row.isEditing(!row.isEditing());
		let iconElement = event.target;
		iconElement.classList.toggle('fa-edit');
		iconElement.classList.toggle('fa-check');

		if (iconElement.classList.contains('fa-check'))
			iconElement.style.color = '#21CA59';
		else 
			iconElement.style.color = '';
	}

	async btn_reject_status_click (status) 
	{
		if (!this.can_reject_order())
		{
			console.error('You must have the stock.super_user access role to reject order.');
			return;
		}

		let result = await Grape.alerts.confirm({
			message: 'Are you sure you want to set the order status to cancelled? This cannot be undone.',
			title: 'Cancel Order', 
			type: 'warning'
		});

		if (result) 
		{
			let selected_status = status;
			let save_status = this.selected_type().statuses.find(status => status.status === selected_status);
			this.selected_status(save_status);
			this.page.save_order();
		}
	}

	async btn_accept_status_click (currentStatus) 
	{
		let save_status = this.selected_type().statuses.find(status => status.status === currentStatus);

		let delivery_counts_valid;
		if (save_status.confirm_sent == true)
			delivery_counts_valid = await this.page.validate_delivery_counts('send');
		if (save_status.confirm_received == true)
			delivery_counts_valid = await this.page.validate_delivery_counts('receive');
		
		if (delivery_counts_valid || (!save_status.confirm_sent && !save_status.confirm_received))
		{
			this.selected_status(save_status);
			this.page.save_order();
		}
	}

	btn_edit_field_click (item) 
	{
		item.editing(true);
	}

	btn_back_click () 
	{
		Grape.navigate('/production/orders_and_execution');
	}

	btn_save_field_click (item) 
	{
		item.editing(false);
		this.page.save_order();
	}

	btn_save_click () 
	{
		this.page.save_order();
	}

	btn_create_click () 
	{
		this.page.save_order();
	}

	btn_download_document_click (item) 
	{
		if (navigator.userAgent.indexOf("Chrome") !== -1)
			this.open_pdf_in_new_window(item);
		else 
		{
			let document = window.open(`/api/stock-management/order/document?order_id=${this.order_id()}&document_name=${item.name}`);
			document.onload = (() => { this.page.update_data(); });
		}
	}

	async open_pdf_in_new_window(item) 
	{
		try 
		{
			const response = await fetch(`/api/stock-management/order/document?order_id=${this.order_id()}&document_name=${item.name}`);
		
			if (!response.ok)
				throw new Error('Network response was not ok');
		
			const blob = await response.blob();
			const blob_url = window.URL.createObjectURL(blob);
			const new_window = window.open('', '_blank');

			if (new_window) 
			{
				new_window.document.write(`<iframe width='100%' height='100%' src='${blob_url}'></iframe><br>`);
				new_window.document.title = 'Document';
				new_window.focus();
			} else
				throw new Error('Unable to open new window. Please check your pop-up blocker settings.');
		} catch (error) {
			console.error('Error:', error);
			Grape.alerts.alert({ title: 'Error', type: 'error', message: error.message });
		} finally {
			this.page.update_data();
		}
	}

	async btn_add_partial_delivery_click (receive) 
	{
		let response = await Grape.dialog.open('PartialDeliveryDialog', { order_id: this.order_id(), order: this.order(), receive: receive});

		if (response)
			await this.page.update_data();
	}

	async btn_view_transaction_click (item) 
	{
		await Grape.dialog.open('ViewTransactionDialog', { order_id: this.order_id(), order_transaction_id: item.order_transaction_id });
	}
	
	current_status_document (item) 
	{
		if (this.selected_status() && this.selected_status().document_name)
			return this.selected_status().document_name === item.name;

		return false;
	}

	async delete_delivery_doc_click (doc)
	{
		try 
		{
			let confirm = await Grape.alerts.confirm({ message: 'Are you sure you want to delete this document?', title: 'Warning', type: 'warning' });

			if (!confirm)
				return;

			let response = await fetch(`/api/order-delivery/doc?order_delivery_document_id=${doc.order_delivery_document_id}`, {
				headers: {'Content-Type': 'application/json'},
				method: 'DELETE'
			});

			let data = await response.json();
			if (data.status == 'OK') 
				Grape.alerts.alert({ type: 'success', title: 'Delete delivery document', message: 'Delivery document was deleted successfully'})
			else
				throw new Error(data.message || 'Error deleting delivery document');
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
		} finally {
			this.page.update_data();
		}
	}

	async upload_delivery_doc_click () 
	{
		document.querySelector('.production_order_document_upload').click();
	}

	add_reference_number (value, event)
	{
		if (event.keyCode !== 13 && (event.type !== 'blur'))
			return true;

		if (this.new_reference_number())
		{
			if (!this.reference_numbers())
				this.reference_numbers([]);
			this.reference_numbers.push(this.new_reference_number());
			this.new_reference_number("");
		}

		return true;
	}

	remove_reference_number (value)
	{
		this.reference_numbers.splice(this.reference_numbers.indexOf(value), 1);
	}

	download_delivery_doc_click (document)
	{
		window.open(`/delivery/document/download/${document.order_delivery_document_id}`);
	}

	update_order_items_type()
	{
		let type = this.type();

		if (type == 'edit' && this.order().confirmed)
			type = 'view';

 		if (this.is_superuser() && !this.order().completed && !this.order().cancelled)
			type = 'edit';
 
		this.order_items_type(type);
	}

	is_superuser ()
	{
		let allowed = false;

		if ((this.type() == 'edit' || this.type() == 'view') && Grape.currentSession.roles.includes('stock.super_user'))
			allowed = true;

		return allowed;
	}

	can_edit_tax () 
	{
		let allowed = true;

		if (Grape.currentSession.roles.includes('stock.all-location-permissions'))
			allowed = true;
		else
		{
			if (allowed)
			{
				let selected_destination = this.selected_destination();
				if (selected_destination)
					if (!this.page.creatable_locations.find(cloc => cloc.location_id == selected_destination.location_id))
						allowed = false;
			}
		}
		return allowed;
	}

	can_progress_order () 
	{
		let allowed = true;

		if (Grape.currentSession.roles.includes('stock.all-location-permissions'))
			allowed = true;
		else
		{
			if (allowed)
				if (this.selected_destination())
					if (!this.page.confirmable_locations.find(cloc => cloc.location_id == this.selected_destination().location_id))
						allowed = false;
		}

		return allowed;
	}

	can_view_location ()
	{
		if (this.filtered_locations().find(location => location.location_id === this.selected_destination().location_id))
			return true;
		else
			return false;
	}
}

class CreateProductionOrderPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new CreateProductionOrderViewModel(this);
		this.confirmable_locations = [];
		this.creatable_locations = [];
		this.all_locations = [];
	}

	async init () 
	{
		document.title = 'Create Production Order';
		this.viewModel.order_date(moment().format('YYYY-MM-DD'));
		this.viewModel.delivery_date(moment().add(7, 'days').format('YYYY-MM-DD'));

		try 
		{
			let [locations, all_locations, type] = await Promise.all([
				Grape.cache.fetch(this.viewModel.type() == "create" ? 'ActiveLocations' : 'Locations'),
				Grape.cache.fetch(this.viewModel.type() == "create" ? 'AllActiveLocations' : 'AllLocations'),
				Grape.cache.fetch('OrderTypes'),
			]);
			this.viewModel.locations(all_locations);

			this.all_locations = all_locations.locations;
			this.creatable_locations = await window.Grape.StockUtils.get_user_locations('CreateOrder');

			let filtered_locations = [];
			locations.forEach((loc) => {
				if (Grape.currentSession.roles.includes('stock.all-location-permissions')
					|| (this.creatable_locations.find(cloc => cloc.location_id == loc.location_id))
					|| this.viewModel.type() == 'view')
					filtered_locations.push(loc);
			});
			this.confirmable_locations = await window.Grape.StockUtils.get_user_locations('ConfirmOrder');
	
			this.viewModel.filtered_locations(filtered_locations);

			let allowed_location_types = ['Internal', 'Raw Materials', 'Finished Goods', 'Workstation'];

			let filtered_destination_locations = filtered_locations.filter(location => 
				location.location_type && allowed_location_types.includes(location.location_type.trim())
			);

			this.viewModel.filtered_destination_locations(filtered_destination_locations);
			
			let order_types = Object.values(type).filter((o_type) => !o_type.batch_created);
			this.viewModel.order_types(order_types);

			let matching_type = this.viewModel.order_types().find(order_type => order_type.type === 'Production Order');
			if (matching_type) 
				this.viewModel.selected_type(matching_type);
	
			if (this.bindings.type == 'edit' || this.bindings.type == 'view')
				await this.update_data();

		} catch (error) {
			Grape.alerts.alert({ title: 'Error', type: 'error', message: error.message });
			console.error('Error fetching data:', error);
		}

		// Register file upload event listener
		const hiddenFileInput = document.querySelector('.production_order_document_upload');
		hiddenFileInput.addEventListener('change', async () => {
			const selectedFile = hiddenFileInput.files[0]; // Get the selected file

			if (selectedFile)
			{
				let header_info = { 
					order_id: this.viewModel.order_id(),
					"X-SessionID": localStorage.getItem('session_id'),
					"Accept": "application/json"
				};

				//SERVER: Upload file(s)
				try
				{
					let response = await fetch('/api/order-delivery/doc/upload', {
						method: 'POST',
						body: new FormData(document.getElementById('delivery_upload_doc')),
						headers: header_info
					});

					let data = await response.json();
					if (response.ok) 
						Grape.alerts.alert({ type: 'success', title: 'Success', message: 'File Successfully Uploaded'});
					else 
						throw new Error(data.message);
				} catch (exception) {
					Grape.alerts.alert({ type:'warning', message: exception, title:`File Upload Error` });
					console.error(exception);
				}
			}
		});
	}
	
	async update_data () 
	{
		this.viewModel.loading(true);
		try 
		{
			let audit;
			if (this.viewModel.type() == 'edit' || this.viewModel.type() == 'view')
			{
				audit = await Grape.fetches.getJSON('/api/record', {
					table: 'v_order_audit_log',
					schema: 'stock',
					filter: [{
						field: 'order_id',
						operand: '=' ,
						value: this.viewModel.order_id()
					}],
					sortorder: 'DESC',
					sortfield: 'date_inserted'
				})

				if (audit.status == 'OK')
				{
					audit.records.forEach(record => record.date_inserted = moment(record.date_inserted).format('YYYY-MM-DD, HH:mm') );
					this.viewModel.order_audit_log(audit.records);
				} else 
					throw new Error(audit.message || audit.code);
			}

			let deliveryDocs = await Grape.fetches.getJSON(`/api/order-delivery/doc?order_id=${this.viewModel.order_id()}`);

			if (deliveryDocs.status == 'OK')
				this.viewModel.delivery_docs(deliveryDocs.documents || []);
			else
				throw new Error(deliveryDocs.message || deliveryDoc.code || 'Error fetching delivery docs');

			let orderData = await Grape.fetches.getJSON(`/api/stock-management/order`, { order_id: this.viewModel.order_id() });

			if (orderData.status === 'OK') 
			{
				let order = orderData.order;
				if (order.target_location && !this.populate_option_field(this.viewModel.locations(), order.target_location))
					this.viewModel.locations.push(this.populate_option_field(this.all_locations, order.target_location));

				if (Grape.currentSession.roles.includes('stock.super_user') || !order.confirmed)
					this.viewModel.can_reject_order(true);
				else
					this.viewModel.can_reject_order(false);

				this.viewModel.order(orderData.order);
				this.viewModel.selected_type(this.populate_option_field(this.viewModel.order_types(), 'Production Order'));
				this.viewModel.selected_status(this.populate_option_field(this.viewModel.order_statuses(), order.order_status));

				if (this.viewModel.selected_status().final === true) 
					if (this.viewModel.type() == 'edit')
						Grape.navigate(`/production/order/view/${this.viewModel.order_id()}`);

				this.viewModel.accept_status(this.viewModel.selected_status().accept_status);
				this.viewModel.reject_status(this.viewModel.selected_status().reject_status);
				this.viewModel.order_date(order.order_date);
				this.viewModel.delivery_date(order.delivery_date);
				this.viewModel.reference_numbers(order.reference_numbers);
				this.viewModel.order_number(order.order_nr);
				this.viewModel.order_note(order.note);

				if ((order.target_location != null) || (order.target_location != undefined))
					this.viewModel.selected_destination(this.populate_option_field(this.viewModel.locations(), order.target_location));

				let initial_selected_items = [];
				this.viewModel.stock_order_items.removeAll();

				order.items.forEach(item => {
					this.viewModel.stock_order_items.push({
						code: item.code,
						stock_item_id: item.stock_item_id,
						bom_id: item.bom_id,
						order_item_id: item.order_item_id,
						description: item.description,
						ppu: ko.observable(item.ppu),
						qty: ko.observable(item.qty),
						qty_received: item.qty_received,
						qty_sent: item.qty_sent,
						delivery_date: ko.observable(item.delivery_date),
						expected_receive_dates: ko.observableArray(item.expected_receive_dates.map(date => ({
							date: ko.observable(date.date),
							qty: ko.observable(parseInt(date.qty))
						})))
					});

					initial_selected_items.push(item);
				});
				this.viewModel.initial_selected_items(initial_selected_items);

				this.viewModel.reload_associated_orders(true);

				if (order.documents != undefined) 
				{
					let order_documents = [];
					order.documents.forEach(document => {
						order_documents.push({
							order_document_id: document.order_document_id,
							path_basename: document.path_basename,
							name: document.name,
							user_id: document.username,
							date_inserted: new Date(document.date_inserted).toISOString().split('T')[0]
						});
					});
					this.viewModel.order_documents(order_documents);
				}

				if (order.transactions != undefined) 
				{
					let order_transactions = [];
					order.transactions.forEach(transaction => {

						// Make qty positive in all cases
						let pos_qty = transaction.qty < 0 ? -transaction.qty : transaction.qty;

						order_transactions.push({
							user_created: transaction.user_created,
							committed: transaction.committed,
							date_effective: transaction.date_effective,
							movement_subtype: transaction.movement_subtype,
							movement_type: transaction.movement_type,
							note: transaction.note,
							receive: transaction.receive,
							user_committed: transaction.user_committed,
							user_inserted: transaction.user_inserted,
							target_location: order.target_location,
							order_transaction_id: transaction.order_transaction_id,
							qty: pos_qty
						})
					});
					this.viewModel.order_transactions(order_transactions);
				}

				if (order.pricing) 
				{
					let order_pricing = [];
					order_pricing.push({
						total_value: order.pricing.total_value,
						total_after_order_taxes: order.pricing.total_after_order_taxes,
						taxes: order.pricing.taxes.map(tax => ({name: tax.name, percent: tax.percent})),
						price_adjustments: order.pricing.price_adjustments,
						isEditing: ko.observable(false)
					});
					this.viewModel.order_pricing(order_pricing)
				}

				if (order.fields != undefined) 
				{
					let order_fields = [];
					Object.entries(order.fields).forEach(([key, value]) => {
						order_fields.push({
							fieldName: key,
							data: value,
							editing: ko.observable(false)
						});
					});
					this.viewModel.order_fields(order_fields);
				}
				document.title = this.viewModel.order_number();
				this.viewModel.loading(false);
				this.viewModel.reload_associated_orders(false);
			}
			else
				throw new Error(orderData.message || orderData.code);

			this.viewModel.update_order_items_type();
			this.viewModel.reload_production_schedule(true);
			this.viewModel.reload_production_schedule(false);
		} catch (error) {
			Grape.alerts.alert({ title: 'Error', type: 'error', message: error.message });
			console.error('Error:', error);
			this.viewModel.loading(false);
		}
	}

	populate_option_field (optionsArray, optionValue)
	{
		if (optionValue === undefined)
			return undefined;

		return optionsArray.find(option => 
			option.type === optionValue || 
			option.status === optionValue || 
			option.name === optionValue
		);
	}

	async save_order ()
	{
		this.viewModel.loading(true);
		if (!this.validate_order())
		{
			this.viewModel.loading(false);
			return;
		}

		let order_items = [];
		for (let item of this.viewModel.stock_order_items())
		{
			let ppu = await this.calculate_bom_item_ppu(item.stock_item_id);
	
			let order_item = {
				stock_item_id: item.stock_item_id,
				qty: item.expected_receive_dates().reduce((sum, delivery) => sum + parseInt(delivery.qty(), 10), 0),
				ppu: ppu,
				expected_receive_dates: item.expected_receive_dates().map(delivery => {
					return {
						date: delivery.date(),
						qty: delivery.qty()
					};
				}),
				bom_id: item.bom_id,
				source_location_id: item.source_location_id
			};
			order_items.push(order_item);
		}

		let component_sources = this.viewModel.components_table_records().map(component => {
			return {
				stock_item_id: component.stock_item_id,
				source_location_id: component.selected_source() ? component.selected_source().location_id : null
			};
		});

		let order = {
			delivery_date: this.viewModel.delivery_date(),
			reference_numbers: this.viewModel.reference_numbers() || [],
			order_date: this.viewModel.order_date(),
			type: this.viewModel.selected_type().type,
			status: this.viewModel.selected_status().status,
			target_location: this.viewModel.selected_destination() ? this.viewModel.selected_destination().name : null,
			order_nr: this.viewModel.order_number(),
			note: this.viewModel.order_note(),
			taxes: this.viewModel.edited_tax(),
			items: order_items,
			component_sources: component_sources
		};

		if (this.viewModel.type() == 'edit' || this.viewModel.type() == 'view')
			order.order_id = this.viewModel.order_id();

		let fields = {};
		for (let item of this.viewModel.order_fields())
			fields[item.fieldName] = item.data;
		order.fields = fields;

		try {
			let result = await Grape.fetches.postJSON('/api/stock-management/order', order);

			this.viewModel.loading(false);
			
			if (result.status == 'OK')
				if (this.viewModel.type() == 'create')
					Grape.navigate(`/production/order/edit/${result.order_id}`);
				else
					this.update_data();
			else 
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
			this.viewModel.loading(false);
		}
	}

	async calculate_bom_item_ppu (stock_item_id)
	{
		try {
			let bom_components = await Grape.fetches.getJSON('/api/record', {
				table: 'v_bom_stock_items',
				schema: 'stock',
				fields: ['component_stock_item_id', 'component_qty', 'supplier_price'],
				filter: [
					{
						field: 'stock_item_id',
						operand: '=',
						value: stock_item_id
					}
				]
			});
	
			let total_ppu = 0;
			bom_components.records.forEach(component => {
				total_ppu += component.supplier_price * component.component_qty;
			});
	
			return total_ppu;
		} catch (error) {
			console.error('Failed to calculate PPU for BOM item:', error);
			throw error;
		}
	}
	
	validate_order () 
	{
		try 
		{
			if (!this.viewModel.selected_status() || 
			(!this.viewModel.selected_destination()) || 
			!(this.viewModel.stock_order_items() && this.viewModel.stock_order_items().length)) 
				throw new Error('Please fill in all the fields & add at least one stock item to the table before saving.')
		
			this.viewModel.stock_order_items().forEach(item => {
				let total_qty = item.expected_receive_dates().reduce((sum, delivery) => sum + parseInt(delivery.qty(), 10), 0);
				item.expected_receive_dates().forEach(delivery => { 
					if (isNaN(delivery.qty()) || delivery.qty() === '')
						throw new Error('Item order quantity should be a number'); 
					if (delivery.qty() < 0) 
						throw new Error('Item order quantity should not be negative'); 
				});
				if (total_qty && total_qty < 0)
					throw new Error('Order quantity must be more than zero');
			});
		} catch (error) {
			Grape.alerts.alert({ type: 'error', title: 'Error', message: error.message });
			console.error(error);
			this.viewModel.loading(false);
			
			return false;
		}

		return true;
	}

	async validate_delivery_counts (type)
	{
		let complete = true;
		this.viewModel.stock_order_items().forEach(item => {
			let outstanding_send_qty = item.expected_receive_dates().reduce((acc, curr) => acc + (parseInt(curr.qty()) || 0), 0) - (item.qty_sent && item.qty_sent !== null ? item.qty_sent : 0) || 0; 
			let outstanding_receive_qty  = item.expected_receive_dates().reduce((acc, curr) => acc + (parseInt(curr.qty()) || 0), 0) - (item.qty_received && item.qty_received !== null ? item.qty_received : 0) || 0;

			if (type === 'send' && outstanding_send_qty > 0)
				complete =  false;
			else if (type === 'receive' && outstanding_receive_qty > 0)
				complete = false;
		});

		if (!complete)
			if (await Grape.alerts.confirm({ message: `There are still outstanding ${type} deliveries, are you sure the order should be progressed to the next status?`, title: 'Warning', type: 'warning' }))
				complete = true;

		return complete;
	}
}

export default {
	route: '/production/order/:type/:order_id',
	page_class: CreateProductionOrderPage,
	template: template
}
