import template from './stock_item_list.html';

class CreateProductListViewModel
{
	constructor (page)
	{
		this.page = page;
		this.type = ko.observable(this.page.bindings.type || 'stock_item');
		this.is_updateData = false;
		this.currentOptions = ko.observableArray();
		this.stock_items = ko.observableArray([]);

		//sort bindings
		this.sortField = ko.observable('description');
		this.sortOrder = ko.observable('ASC');

		this.search_string = ko.observable('');
		this.current_page_number = ko.observable(1);
		this.current_page_size = ko.observable(25);
		this.page_count = ko.observable(1);

		this.stock_groups = ko.observableArray([]);
		this.stock_tags = ko.observableArray([]);
		this.selected_groups = ko.observableArray([]);
		this.selected_tags = ko.observableArray([]);
		this.types = ko.observableArray(['All', 'Stock Item', 'BOM']);
		this.selected_type = ko.observable('All');
		this.hide_type_dropdown = ko.observable(false);
		this.display_inactive = ko.observable(false);

		this.selected_groups.subscribe(() => {
			this.current_page_number(1);
			this.page.updateData();
		});

		this.selected_tags.subscribe(() => {
			this.current_page_number(1);
			this.page.updateData();
		});

		this.display_inactive.subscribe(() => {
			this.current_page_number(1);
			this.page.updateData();
		});

		this.selected_type.subscribe(() => {
			this.current_page_number(1);
			this.page.updateData();
		});
	}

	sortClick (field)
	{
		this.sortField(field);

		if (this.sortOrder() == 'ASC')
			this.sortOrder('DESC');
		else
			this.sortOrder('ASC');

		this.page.updateData();
	}

	item_click (item)
	{
		this.selected_type(item);
	}
}

class ProductsPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new CreateProductListViewModel(this);

		const searchInput = document.getElementById('searchInput');
		searchInput.addEventListener('keyup', (event) => {
			if (event.key === 'Enter')
				this.updateData();
		});
	}

	async init ()
	{
		document.title = 'Stock Items';

		if (this.viewModel.type() === 'bom')
		{
			this.viewModel.selected_type('BOM');
			this.viewModel.hide_type_dropdown(true);
		}

		await this.updateData();

		let options = {
			table: 'v_stock_item',
			schema: 'stock',
			fields: ['stock_group', 'stock_tags'],
		};
		
		let result = await Grape.fetches.getJSON('/api/record', options);
		
		if (result.status != 'ERROR') 
		{
			let stock_groups = [];
			let stock_tags = [];
			result.records.forEach((row) => {
				if (stock_groups.indexOf(row.stock_group) === -1)
					stock_groups.push(row.stock_group);

				if (row.stock_tags && row.stock_tags.length > 0)
					row.stock_tags.split(',').forEach(tag => {
						if (stock_tags.indexOf(tag) === -1)
							stock_tags.push(tag);
					});
			});

			this.viewModel.stock_groups(stock_groups);
			this.viewModel.stock_tags(stock_tags);
		} else
			throw new Error(result.message || result.code);
	}

	async updateData () 
	{
		if (this.viewModel.is_updateData) 
			return;

		this.viewModel.is_updateData = true;

		let filter_base = [];

		if (this.viewModel.selected_type() === 'Stock Item')
			filter_base.push({
				field: 'is_bom',
				operand: '=',
				value: 'false'
			})
		else if (this.viewModel.selected_type() === 'BOM')
			filter_base.push({
				field: 'is_bom',
				operand: '=',
				value: 'true'
			})

		if (this.viewModel.selected_groups().length > 0)
			filter_base.push({
				field: 'stock_group',
				operand: 'IN',
				value: this.viewModel.selected_groups()
			});

		if (!this.viewModel.display_inactive())
			filter_base.push({
				field: 'in_use',
				operand: '=',
				value: true
			});

		if (this.viewModel.selected_tags().length > 0)
			filter_base.push({
				field: 'stock_tags',
				operand: 'ILIKE',
				value: `%${this.viewModel.selected_tags()}%`
			});

		let options = {
			table: 'v_stock_item',
			schema: 'stock',
			fields: ['stock_item_id', 'is_bom', 'description', 'other_names', 'code', 'stock_group', 'stock_tags', 'in_use'],
			offset: 0,
			sortorder: this.viewModel.sortOrder(),
			sortfield: this.viewModel.sortField(),
			limit: this.viewModel.current_page_size(),
			filter_join: 'AND',
			filter: filter_base
		};

		// LOGIC: Search on both description and code
		if (this.viewModel.search_string() && this.viewModel.search_string() != '')
		{
			options.filter_join = "OR";
			options.filter = [
				{
					join: 'AND',
					filter: filter_base.concat([
						{ 
							field: 'description', 
							operand: 'ILIKE', 
							value: `%${this.viewModel.search_string()}%` 
						}
					])
				},
				{
					join: 'AND',
					filter: filter_base.concat([
						{ 
							field: 'code', 
							operand: 'ILIKE', 
							value: `%${this.viewModel.search_string()}%` 
						}
					])
				}
			];
		}

		// LOGIC: Pagination
		if (this.viewModel.current_page_number() && this.viewModel.current_page_size())
		{
			options.limit = this.viewModel.current_page_size();
			options.offset = (this.viewModel.current_page_number()-1) * this.viewModel.current_page_size();
		}

		this.viewModel.currentOptions(options);

		try
		{
			let result = await Grape.fetches.getJSON('/api/record', options);
			
			if (result.status != 'ERROR')
			{
				this.viewModel.stock_items(result.records);
				this.viewModel.is_updateData = false;
				this.viewModel.page_count(Math.ceil(result.total/result.limit));
			}
			else
				throw new Error(result.message || result.code);
		} catch (error) {
			Grape.alerts.alert({ title: 'Error', type: 'error', message: error.message });
			console.error(error);
		}
	}

	async btn_add_product_click ()
	{ 
		let navigation = '';
		try
		{
			let obj = await Grape.dialog.open('StockItemEdit', { type: this.viewModel.type() });

			if (!obj)
				return;

			let url = '/api/stock-management/stock-item';

			if (this.viewModel.type() == 'bom')
			{
				url = '/api/stock-management/bom'
				let bom_obj = await Grape.dialog.open('BomItemEdit', {});

				if (bom_obj === undefined)
					return;

				if (bom_obj.error)
					throw new Error(bom_obj.error);

				obj = {...obj, ...bom_obj};
			}

			let result = await Grape.fetches.postJSON(url, obj);

			if (result.status != 'ERROR')
			{
				if (this.viewModel.type() == 'bom')
					navigation = `/stock/item/view/${result.stock_item_id}/bom`;
				else
					navigation = `/stock/item/view/${result.stock_item_id}/stock_item`;
				
				Grape.alerts.alert({
					type: 'success', 
					message: 'Product ' + obj.description + ' saved', 
					title: 'Product Saved', 
				});
			}
			else
				throw new Error(result.message);
		} catch (error) {
			Grape.alerts.alert({ title: 'Error', type: 'error', message: error });
			console.error(error);
		} finally {
			await this.updateData();
			await Grape.cache.refresh('StockItems');
			Grape.cache.refresh('ActiveStockItems');
			Grape.cache.refresh('StockItemTags');
			Grape.navigate(navigation);
		}
	}
	
	btn_bulk_add_click ()
	{
		Grape.navigate('/stock_item/bulk_add/');
	}

	async btn_download_item_list_click ()
	{
		let options = this.viewModel.currentOptions();
		options.limit = 100000;
		options.offset = 0;

		let filter_values = {
			'Selected Group(s)': this.viewModel.selected_groups().length > 0 ? this.viewModel.selected_groups() : 'None',
			'Selected Tag(s)': this.viewModel.selected_tags().length > 0 ? this.viewModel.selected_tags() : 'None',
			'Display Inactive': this.viewModel.display_inactive() ? 'Yes' : 'No'
		};

		let params = { 
			options: options, 
			headers: [
				{ field: 'description', title: 'Description' },
				{ field: 'code', title: 'Code' },
				{ field: 'stock_group', title: 'Stock Group' },
				{ field: 'stock_tags', title: 'Stock Tags' }
			],
			filters: filter_values
		};
		let filename = 'Stock Item List';

		let url = await Grape.fetches.buildURL(`/download/record/${filename}`, params);
		
		window.open(url.href);
	}

	btn_search_click () 
	{
		this.updateData();
	}

	btn_page_click (page_number)
	{
		this.viewModel.current_page_number(page_number);
		this.updateData();
	}
}

export default {
	route: '[/]stock_item/list/:type',
	page_class: ProductsPage,
	template: template
}